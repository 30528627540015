import {Tr} from "@/i18n/i18n";
import {ChestContentType} from "@/store/chest/types";
import {EffectType} from "@/store/artifact/types";
import {ConsumableType} from "@/store/consumable/types";

export const RU = {
    
    'blocked': 'Пользователь не доступен для связи',
    'ke': '-КЕ',
    'luchshaja.pobeda': 'ЛУЧШАЯ ПОБЕДА:',
    'troyke': 'ТРОЙКЕ',
    'voidite.v.top.n.i.polu4ite': 'Войдите в топ-{0} и получите',
    'dobivaniy': ' ДОБИВАНИЙ',
    'obmenjat': 'ОБМЕНЯТЬ',
    'vosstanovit.zdorove.vsem.gerojam': 'ВОССТАНОВИТЬ ЗДОРОВЬЕ ВСЕМ ГЕРОЯМ?',
    'artefakt.na.maksimalnom.urovne': 'АРТЕФАКТ НА МАКСИМАЛЬНОМ УРОВНЕ',
    'sila.na.maksimalnom.urovne': 'СИЛА ИМЕЕТ МАКСИМАЛЬНЫЙ РАНГ',
    'dar.poka.ne.dostupen': 'ДАР ПОКА НЕ ДОСТУПЕН',
    'vibranniy.artefakt.budet.razobran': 'Выбранный артефакт будет разобран. Далее мы выберем героя, который получит 25% силы артефакта',
    'tsena.za.edinitsu': 'Цена за единицу',
    'er.no.nm': 'Надо ввести хоть какой-то никнейм!',
    'er.no.ml.or.phn': 'Надо ввести хоть какую-то почту или телефон!',
    'izmeneno': 'Изменено',
    'nachalo.igri': 'НАЧАЛО ИГРЫ: ',
    'igrok.povishen.do.uchastnika': 'Игрок повышен до участника',
    'viberite.vremja.na.kotoroe.hotite.zabanit.igroka': 'Выберите время, на которое хотите забанить игрока',
    'vibrat': 'ВЫБРАТЬ',
    'doplatit': 'Доплатить',
    'zvuk.vikljuchen': 'ЗВУК ВЫКЛЮЧЕН',
    'sapogiskorohodi.nadeti': 'Сапоги-скороходы надеты',
    'bank': 'БАНК',
    'zdorove.geroev.vremenno.umenshaetsja': 'ЗДОРОВЬЕ ГЕРОЕВ ВРЕМЕННО УМЕНЬШАЕТСЯ',
    'hour': 'Ч. ',
    'level.braket': ' (ур. ',
    'terjaet': ' теряет ',
    'po.urovnju': 'По уровню',
    'po.shliapam': 'По шляпам',
    'po.paporotnikam': 'Папоротник',
    'sbor.shliap': 'Сбор шляп',
    'sbor.paporotnika': 'Папоротник',
    'izmenenija.vstupjat.v.silu.so.sledujuschego.zadanija.': '*Изменения вступят в силу со следующего задания. ',
    'vremja': 'ВРЕМЯ ',
    'a.teper.nazhmi.uluchshit.': 'А теперь нажми "Улучшить".',
    'igrok.ponizhen.do.novichka': 'Игрок понижен до новичка',
    'do.povtornogo.otpravlenija': 'ДО ПОВТОРНОГО ОТПРАВЛЕНИЯ:',
    'nechego.obmenivat': 'Нечего обменивать',
    'otmena': 'ОТМЕНА',
    'nadet.sapogi.skorohodi': 'НАДЕТЬ САПОГИ СКОРОХОДЫ',
    'zadaniy.vipolneno': 'ЗАДАНИЙ ВЫПОЛНЕНО:',
    'y.uroven': '-й уровень',
    'y.uroven.monstra': '-й уровень монстра',
    'slavnaja.pobeda': 'СЛАВНАЯ ПОБЕДА!',
    'and': 'и',
    'izumrud': ' ИЗУМРУД',
    'izumruda': ' ИЗУМРУДА',
    'toward': 'к',
    'viberite.deystvie': 'ВЫБЕРИТЕ ДЕЙСТВИЕ',
    'teper.s.nami': ' ТЕПЕРЬ С НАМИ!',
    'podat.zajavku': 'ПОДАТЬ ЗАЯВКУ',
    'minute': 'м',
    'smenit.za': 'Сменить за ',
    'v.boju.mogut.uchastvovat.lish': 'В БОЮ МОГУТ УЧАСТВОВАТЬ ЛИШЬ ',
    'zdorove.vosstanovleno': 'Здоровье восстановлено',
    'klass': 'КЛАСС: ',
    'nagrada': 'НАГРАДА',
    'postroyka.gotova': 'ПОСТРОЙКА ГОТОВА',
    'za': 'За ',
    'spisok.pust': 'СПИСОК ПУСТ',
    'pusto': 'ПУСТО',
    'dostignite': 'ДОСТИГНИТЕ ',
    'nashi.geroi.raneni.v.bojah': 'НАШИ ГЕРОИ РАНЕНЫ В БОЯХ',
    'nm.short': 'Ммм.. Название коротковато',
    'zaschita': 'Защита',
    'magazin.predmetov.dostupen.s': 'Магазин предметов доступен с ',
    'igrok.priglashen.v.klan': 'Игрок приглашен в клан',
    'feniks': 'ФЕНИКС',
    'novaja.pokupka': 'НОВАЯ ПОКУПКА',
    'zaschita.plus': 'ЗАЩИТА +',
    'troll': 'ТРОЛЛЬ',
    'close': 'Закрыть',
    'back': 'Назад',
    'name.validation': 'Название не может быть пустым.',
    'name.length.validation': 'Название не может быть длиннее 40 символов.',
    'text.empty.validation':'Текст не может быть пустым.',
    'text.length.validation':'Текст комментария не может быть длиннее 3000 символов.',
    'topic.length.validation':'Текст топика не может быть длиннее 20,000 символов.',
    'konets.puti': 'КОНЕЦ ПУТИ',
    'maks.ur': 'МАКС. УР: ',
    'a.teper.vozmem.revansh.dumaju.teper.to.mi.pobedim.etih.monstrov.': 'А теперь возьмем реванш. Думаю, теперь то мы победим этих монстров.',
    'seychas': '(сейчас ',
    'shans.otrazit.atk.': '-50%. ШАНС ОТРАЗИТЬ АТК.',
    'vosstanovit': 'ВОССТАНОВИТЬ',
    'chasov': '12 часов',
    'chat': 'ЧАТ',
    'zamestitel.lidera': 'Заместитель лидера',
    'eto.svobodniy.slot.dlja.predmeta.': 'Это свободный слот для предмета.',
    'po.prohozhdeniju': 'По прохождению',
    'uluchshit': 'Улучшить',
    'explore': 'Изучить',
    'rub': 'РУБ.',
    'rub.small': 'руб',
    'uspeshno.uskoreno': 'Успешно ускорено',
    'deystvija': 'ДЕЙСТВИЯ',
    'improve.creature.classes':'Улучшайте классы существ',
    'kupi.izumrudi.i.poluchi..v.podarok': 'КУПИ ИЗУМРУДЫ И ПОЛУЧИ +100% В ПОДАРОК!',
    'kupi.izumrudi.i.poluchi..v.podarok2': 'КУПИ ИЗУМРУДЫ И ПОЛУЧИ +50% В ПОДАРОК!',
    'opit.plus': 'ОПЫТ +',
    'factory.gift.value.plus': 'ЦЕННОСТЬ ПОДАРКА ФАБРИКИ +',
    'dop.uron.dzhinnam': '- ДОП. УРОН ДЖИННАМ',
    'o.klane': 'О КЛАНЕ',
    'priglasit': 'ПРИГЛАСИТЬ',
    'iskljuchen': 'Исключен',
    'posle.voskreshenija.trizhdi.atakuet.obidchika': '-ПОСЛЕ ВОСКРЕШЕНИЯ ТРИЖДЫ АТАКУЕТ ОБИДЧИКА',
    'u.klana.nedostatochno.sredstv': 'У клана недостаточно средств',
    'stoimost.postroyki': 'СТОИМОСТЬ ПОСТРОЙКИ: ',
    'dostizhenija': 'ДОСТИЖЕНИЯ:',
    'popolnenie.kazni': 'ПОПОЛНЕНИЕ КАЗНЫ',
    'one.minuta': '1 минута',
    'viigrano.bitv': 'ВЫИГРАНО БИТВ:',
    'po.sile': 'По силе',
    'clan.po.urovniu': 'По уровню',
    'besplatnoe.otkritie.slota.v.podarok': 'БЕСПЛАТНОЕ ОТКРЫТИЕ СЛОТА В ПОДАРОК!',
    'dlja.vas.teper.dostupna.prokachka.klassa.geroja': 'Для Вас теперь доступна прокачка класса героя!',
    'izza.nizkogo.atmosfernogo.davlenija': 'Из-за низкого атмосферного давления в мире снегов нельзя находиться слишком долго. Пора домой. Мы сможем вернуться через 6 часов.',
    'atak.dvazhdi.vtoraja.atk.sluch.vragu': '-АТАК. ДВАЖДЫ. ВТОРАЯ АТК. СЛУЧ. ВРАГУ',
    'dzhinn': 'ДЖИНН',
    'v.vashem.klane.maksimalnoe.kolichestvo.uchastnikov': 'В Вашем клане максимальное количество участников. Повысьте уровень клана, чтобы пригласить больше людей.',
    'otkritie.slota': 'ОТКРЫТИЕ СЛОТА',
    'comma.lev': ', ур. ',
    'dop.uron.demonam': '-ДОП. УРОН ДЕМОНАМ',
    'dalee': 'ДАЛЕЕ',
    'ne.hvataet.izumrudov.kupite.v.banke': 'Не хватает изумрудов? Купите в банке!',
    'uchastniki': 'УЧАСТНИКИ',
    'razblokirovat.slot': 'РАЗБЛОКИРОВАТЬ СЛОТ?',
    'krasnie.kristalli': 'Красные кристаллы',
    'otlichno.mozhem.podozhdat.sekund.ili.uskorit.i.ne.zhdat.': 'Отлично. Можем подождать 15 секунд или ускорить и не ждать.',
    'izuchaem.silu': 'ИЗУЧАЕМ СИЛУ ',
    'comma.zoloto': ', золото: ',
    'etot.slot.mozhno.razblokirovat': 'ЭТОТ СЛОТ МОЖНО РАЗБЛОКИРОВАТЬ',
    'zdorove': 'Здоровье',
    'slot': 'СЛОТ',
    'vash.hod': 'ВАШ ХОД!',
    'moi.geroi': 'МОИ ГЕРОИ',
    'rinok': 'РЫНОК',
    'vot.bi.posmotret.chto.v.etom.sunduke': 'Вот бы посмотреть, что в этом сундуке.. Но его загораживают хищные растения! Кликни на них, чтобы сразимся с ними!',
    'radi.vas.videt': 'РАДЫ ВАС ВИДЕТЬ',
    'snizh.zaschiti.atakujuschego': '-СНИЖ. ЗАЩИТЫ АТАКУЮЩЕГО',
    's.vozvrascheniem': 'С ВОЗВРАЩЕНИЕМ!',
    'ref.welcome.header': 'добро пожаловать!',
    'ref.welcome.info': 'приветственный бонус',
    'ref.welcome.label': 'За регистрацию по реферальной ссылке',
    'ref.bonus.header': 'Бонус',
    'ref.bonus.info': 'Реферальный бонус',
    'ref.bonus.label': 'За привлечение в игру друзей',
    'ref.bonus.comment': 'Также ваш клан получает',
    'nagradi.bracket': 'НАГРАДЫ (',
    'vi': 'Вы ',
    'sila.izuchena': 'Сила изучена!',
    'sila.uluchshena': 'Класс улучшен!',
    'net.bonusov': '(нет бонусов)',
    'ataka': 'Атака',
    'v.rodnoy.mir': 'В РОДНОЙ МИР',
    'soberite': 'СОБЕРИТЕ',
    'nm.long': 'Название длинновато. Максимум символов: ',
    'ukazatel': 'УКАЗАТЕЛЬ',
    'pobedili.dot': 'победили.',
    'ataki.i.zaschiti': 'атаки и защиты',
    'dop.uron.zverjam': '-ДОП. УРОН ЗВЕРЯМ',
    'krasniy.kristall': ' красный кристалл',
    'povisit.do.uchastnika': 'ПОВЫСИТЬ ДО УЧАСТНИКА',
    'ch.m.12.59': '12Ч. 59М.',
    'klan': 'КЛАН',
    'hint': 'СОВЕТ',
    'hint2': 'ПОДСКАЗКА',
    'delete.hint': 'Удалить подсказку',
    'hint.unavailable': 'Подсказка недоступна',
    'klana': 'КЛАНА',
    'brosili.vizov': 'бросили вызов ',
    'chelovek': 'ЧЕЛОВЕК',
    'day.dot': 'д. ',
    'zavershit': 'ЗАВЕРШИТЬ',
    'nam.predstoit.issledovat.etot.mir': 'Нам предстоит исследовать этот мир, сражаться и выживать.',
    'uzhe.est.akkaunt': 'УЖЕ ЕСТЬ АККАУНТ?',
    'nekromant': 'НЕКРОМАНТ',
    'smotri.mi.nashli.zoloto': 'Смотри, мы нашли золото! Давай подберем его, оно пригодится нам в будущем.',
    'zadaniy.poka.net': 'ЗАДАНИЙ ПОКА НЕТ',
    'porazhenie': 'ПОРАЖЕНИЕ',
    'pobeda': 'ПОБЕДА',
    'dlja.pokupki.ne.hvataet': 'Для покупки не хватает ',
    'smenit.imja': 'СМЕНИТЬ ИМЯ',
    'email.phone': 'ПОЧТА/ТЕЛЕФОН',
    'reyting.klanov': 'РЕЙТИНГ КЛАНОВ',
    'ostalos.vremeni': 'ОСТАЛОСЬ ВРЕМЕНИ',
    'iskat': 'ИСКАТЬ',
    'v.magazin': 'В магазин',
    'username': 'username',
    'er.nm.shrt': 'Ммм.. Никнейм коротковат',
    'iz.nih.stsenarnih': 'ИЗ НИХ СЦЕНАРНЫХ:',
    'kubkov.na.arene': 'МЕСТО НА АРЕНЕ:',
    'fraction.points': 'ОЧКОВ ФРАКЦИЙ:',
    'luchshaja.pobeda.ur': 'Лучшая победа: ур. ',
    'gotovo': 'Готово',
    'povisit.do.predstavitelja': 'ПОВЫСИТЬ ДО ПРЕДСТАВИТЕЛЯ',
    'poluchit.nagradu': 'Получить награду',
    'tekuschiy': ' (ТЕКУЩИЙ)',
    'ataka.i.zaschita': 'Атака и защита',
    'neobhodimo.poluchit': 'Необходимо получить ',
    'dlja.uluchshenija.nuzhna': 'Для улучшения нужна ',
    'podobrat': 'ПОДОБРАТЬ',
    'vse.sloti.zanjati': 'ВСЕ СЛОТЫ ЗАНЯТЫ',
    'nagrada.poluchena': 'Награда получена',
    'ataka.plus': 'АТАКА +',
    'otlichno.teper.klikni.na.geroja.': 'Отлично, теперь кликни на героя.',
    'ataki': 'атаки',
    'er.ps.empt': 'Ой-ой! Пароль не может быть пустым',
    'er.enter.valid.mail.or.phone': 'Телефон должен начинаться со знака +, почта должна содержать знак @',
    'er.invalid.phone': 'Некорректный номер телефона',
    'er.invalid.mail': 'Некорректный почтовый адрес',
    'vvedite.tekst': 'ВВЕДИТЕ ТЕКСТ...',
    'hochu.v.klan': 'ХОЧУ В КЛАН!',
    'rekruting': 'РЕКРУТИНГ',
    'da': 'ДА!',
    'net': 'НЕТ',
    'napisat': 'НАПИСАТЬ',
    'factory.info':'ПРОИЗВОДИТ ЕЖЕДНЕВНО ШЕСТЕРЕНКИ (ШАНС 30%) ИЛИ СЛУЧАЙНЫЕ РЕСУРСЫ (ШАНС 70%) ДЛЯ УЧАСТНИКОВ КЛАНА.',
    'hall.info':'ПОЗВОЛЯЕТ ПРОКАЧИВАТЬ ОПРЕДЕЛЕННЫЕ КЛАССЫ ГЕРОЕВ.',
    'zaschischaet.soklanovtsev.v.bojah.otrazhaja.chast.udarov': 'ЗАЩИЩАЕТ СОКЛАНОВЦЕВ В БОЯХ, ОТРАЖАЯ ЧАСТЬ УДАРОВ.',
    'privet.ja.cheshirskiy.kot.i.tvoy.verniy.kompanon.v.zazerkale': 'Привет! Я чеширский кот, и твой верный компаньон в Зазеркалье.',
    'ne.zhdat.i.poluchit': 'Не ждать и получить ',
    'vibrano': ' (выбрано) ',
    'vi.uvereni.chto.hotite.sdatsja': 'ВЫ УВЕРЕНЫ, ЧТО ХОТИТЕ СДАТЬСЯ?',
    'viberi.komu.peredat.predmet': 'ВЫБЕРИ, КОМУ ПЕРЕДАТЬ ПРЕДМЕТ',
    'vremja.vipolnenija': 'время выполнения: ',
    'takzhe.vi.mozhete.pereyti.k.reytingu.klanov': 'Также Вы можете перейти к рейтингу кланов и подать заявку в понравившийся клан. Или же создать свой собственный клан.',
    'eliksir': 'Эликсир',
    'kazna': 'КАЗНА',
    'klan.eto.obedinenie.igrokov': 'Клан - это объединение игроков с целью пройти игру проще и интереснее.',
    'igrok.ponizhen.do.predstavitelja': 'Игрок понижен до представителя',
    'vremja.uluchshenija': 'ВРЕМЯ УЛУЧШЕНИЯ',
    'vremja.izuchenia': 'ВРЕМЯ ИЗУЧЕНИЯ',
    'v.komandnom.zadanii.uchastvujut.vse.chleni.klana.n': '*В командном задании участвуют все члены клана.\n',
    'v.snezhnom.mire': 'В СНЕЖНОМ МИРЕ',
    'igrok.povishen.do.zam.lidera': 'Игрок повышен до зам. лидера',
    'posledniy.vhod': 'ПОСЛЕДНИЙ ВХОД: ',
    'otdaem': 'ОТДАЕМ',
    'predstavitel': 'Представитель',
    'poka.vas.ne.bilo': 'ПОКА ВАС НЕ БЫЛО',
    'minut.15': '15 минут',
    'soobschenie': 'Сообщение',
    'iskljuchen.iz.klana': 'Исключен из клана',
    'geroi': 'ГЕРОИ',
    'cards': 'КАРТЫ',
    'sinie.kristalli': 'Синие кристаллы',
    'ustanovite.predpochtitelnuju.slozhnost.zadanija': 'Установите предпочтительную сложность задания в снежном мире на Ваше усмотрение!',
    'arena': 'АРЕНА',
    'change': 'изменить',
    'vi.smozhete.v.ljuboy.moment.izmenit.sostav.geroev': 'ВЫ СМОЖЕТЕ В ЛЮБОЙ МОМЕНТ ИЗМЕНИТЬ СОСТАВ ГЕРОЕВ, УЧАСТВУЮЩИХ В БОЮ',
    'po.aktsii': 'ПО АКЦИИ',
    'to.wheel': 'КРУТИТЬ КОЛЕСО',
    'to.wheel.free': 'КРУТИТЬ БЕСПЛАТНО',
    'zakrit': 'ЗАКРЫТЬ',
    'all.emoji': 'Все эмоции',
    'snizhenie.urona.atakujuschego': '-СНИЖЕНИЕ УРОНА АТАКУЮЩЕГО',
    'uskorit': 'Ускорить',
    'noviy.uroven.poluchen': 'Новый уровень получен!',
    'prigoditsja.dlja.uluchshenija': 'ПРИГОДИТСЯ ДЛЯ УЛУЧШЕНИЯ',
    'snachala.zavershim.obuchenie': 'Сначала завершим обучение',
    'vam': 'Вам',
    'minut.30': '30 минут',
    'vvedite.imja': 'ВВЕДИТЕ ИМЯ',
    'vvedite.nazvanie': 'ВВЕДИТЕ НАЗВАНИЕ',
    'podtverzhdenie': 'ПОДТВЕРЖДЕНИЕ',
    'istorija.areni': 'ИСТОРИЯ АРЕНЫ',
    'vashi.geroi': 'ВАШИ ГЕРОИ',
    'ligi.areni': 'ЛИГИ АРЕНЫ',
    'players.on.positions': 'Игрокам на местах',
    'liga.1': 'Лига новичков',
    'liga.2': 'Деревянная лига',
    'liga.3': 'Каменная лига',
    'liga.4': 'Бронзовая лига',
    'liga.5': 'Серебряная лига',
    'liga.6': 'Золотая лига',
    'liga.7': 'Алмазная лига',
    'liga.8': 'Лига мастеров',
    'liga.9': 'Лига чемпионов',
    'daily.arena.reward': 'Ежедневная награда арены',
    'nagrada.za.sobitie': 'Награда за событие',
    'nagrada.za.sobitie.nachislena': 'За участие в событии вам начислена награда:',
    'arena.info.1': 'Побеждайте на арене и занимайте место оппонента!',
    'arena.info.2': 'На арене действуют все усилители, кроме Эликсира убеждения. Также Ужас феникса не действует на Асгардианских фениксов',
    'arena.info.3': 'Получайте награду ежедневно в {0} по местному времени; следующая награда через ',
    'arena.info.4': 'Чем выше место - тем больше награда!',
    'arena.info.5': 'Если после победы ваша позиция не изменилась - значит атакуемый игрок сразился раньше и ускользнул',
    'arena.reward.time.info': '* Время до получения награды может увеличиться',
    'current.league': 'Текущая лига',
    'best.league': 'Лучшая лига',
    'current.position': 'Текущее место',
    'best.position' : 'Лучшее место',
    'position' : 'Место',
    'position.in.rating' : 'Место в рейтинге',
    'stsenarnoe': 'СЦЕНАРНОЕ',
    'sidequest': 'ПОБОЧНОЕ',
    'event': 'СОБЫТИЕ',
    'novoe': 'новое',
    'zmey': 'ЗМЕЙ',
    'novaja.nahodka': 'НОВАЯ НАХОДКА',
    'zamena.geroja': 'ЗАМЕНА ГЕРОЯ',
    'uchastnikov': 'Участников: ',
    'sek': ' сек.',
    'novoe.zadanie.nachnetsja.zavtra.v': 'НОВОЕ ЗАДАНИЕ НАЧНЕТСЯ ЗАВТРА В ',
    'zver': 'ЗВЕРЬ',
    'imeetsja': 'ИМЕЕТСЯ',
    'zaschiti': 'защиты',
    'esche': 'ЕЩЕ ',
    'snachala.nuzhno.chtoto.vnesti': 'Сначала нужно что-то внести',
    'dobro.pozhalovat.na.rinok': 'Добро пожаловать на рынок! На рынке можно купить красные и синие кристаллы.',
    'vpered': 'Вперед!',
    'ponizit.do.predstavitelja': 'ПОНИЗИТЬ ДО ПРЕДСТАВИТЕЛЯ',
    'otlichno': 'Отлично',
    'poisk.igroka': 'Поиск игрока',
    'poisk.klana': 'Поиск клана',
    'otpravit': 'ОТПРАВИТЬ',
    'vi.bolshe.ne.sostoite.v.klane': 'Вы больше не состоите в клане.',
    'siniy.kristall': ' синий кристалл',
    'nabirayte.opit.i.poluchayte.k.zdorovju': 'Набирайте опыт, и получайте +{0} к здоровью за каждый уровень',
    'nagrad.poka.net': 'НАГРАД ПОКА НЕТ',
    'demon': 'ДЕМОН',
    'magazin.geroev.dostupen.s': 'Магазин героев доступен с ',
    'zajavka.na.vstuplenie.v.klan.podana': 'Заявка на вступление в клан подана',
    'artefakt.uspeshno.perekovan': 'Артефакт успешно перекован',
    'sobrano': 'Собрано ',
    'povisit.do.zam.lidera': 'ПОВЫСИТЬ ДО ЗАМ. ЛИДЕРА',
    'teper.v.otrjade': ' теперь в отряде!',
    'viberite.deystvie.': 'ВЫБЕРИТЕ ДЕЙСТВИЕ.',
    'zaplatit.za.nih.mozhno.zolotom': 'Заплатить за них можно золотом или другими кристаллами. Одно за другое. Попробуем?',
    'iskljuchit.iz.klana': 'ИСКЛЮЧИТЬ ИЗ КЛАНА',
    'emissar.privetstvuju': 'Эмиссар: приветствую, ',
    'dot.no.povorot.zaschischajut': '. НО ПОВОРОТ ЗАЩИЩАЮТ ',
    'pravila.u.menja.takie.pri.perekovke.vibranniy': 'Правила у меня такие: при перековке выбранный артефакт исчезает, а любой выбранный герой получает 25% силы перекованного артефакта.',
    'chat.clana': 'Чат клана',
    'zoloto': 'Золото',
    'vizov': 'вызов ',
    'profil': 'ПРОФИЛЬ',
    'maks.dot': 'МАКС.',
    'slozhnoe': 'СЛОЖНОЕ',
    'noviy.geroy': 'НОВЫЙ ГЕРОЙ',
    'izumrudi': 'Изумруды',
    'standartnoe': 'СТАНДАРТНОЕ',
    'start.zadanija': 'СТАРТ ЗАДАНИЯ: ',
    'cherez': 'ЧЕРЕЗ ',
    'neverojatnoe': 'НЕВЕРОЯТНОЕ',
    'you.br': '(ВЫ)',
    'your.clan.br': '(ваш)',
    'clan.history': 'История клана',
    'dop.uron.cheloveku': '-ДОП. УРОН ЧЕЛОВЕКУ',
    'reyting': 'Рейтинг',
    'prizy': 'Призы',
    'place': 'Место',
    'places': 'Места',
    'vi.na.{0}.meste': 'Вы на {0}-м месте',
    'klan.otkroet.novie.vozmozhnosti': 'Клан откроет новые возможности, даст игровые бонусы. Клан - это свои люди, которые придут на помощь. Наконец, клан - это общение и друзья.',
    'sredstva.za.postroyki.vzimajutsja.iz.bjudzheta.klana': '*Средства за постройки взимаются из бюджета клана.',
    'unikalniy.drakon.so.skidkoy': 'Уникальный дракон со скидкой!',
    'nedavno.ja.poluchil.poslanie.ot.belogo.krolika': 'Недавно я получил послание от белого кролика. Он хотел сказать нам что-то важное, звал на помощь. Нам нужно разыскать его.',
    'viberi.geroja.kotoriy.bolshe.tebe.podhodit': 'ВЫБЕРИ ГЕРОЯ, КОТОРЫЙ БОЛЬШЕ ТЕБЕ ПОДХОДИТ',
    'gdpr.title': 'Персонализированная реклама',
    'gdpr.body': 'Разрешая этому приложению показывать персонализированную рекламу, вы помогаете приложению оставаться бесплатным. Приложение также собирает и обрабатывает вашу персональную информацию для  повышения качества. Нажимая кнопку ПРИНЯТЬ, вы соглашаетесь с условиями Политики конфиденциальности.',
    'gdpr.accept.button': 'Принять',
    'gdpr.decline.button': 'Отклонить',
    'gdpr.privacy': 'Политика конфиденциальности',
    'gdpr.terms': 'Правила использования',
    'beeline.welcome.title': 'Важная информация',
    'beeline.welcome.body': 'Нажимая кнопку Продолжить, вы соглашаетесь с условиями Пользовательского соглашения',
    'beeline.welcome.accept.button': 'Продолжить',
    'beeline.payment.confirmation.title': 'Подтверждение платежа',
    'beeline.payment.confirmation.text': 'Средства будут списаны с вашего мобильного баланса и зачислены на счет \n в течение нескольких секунд.',
    'beeline.payment.confirmation.accept_text': 'Подтвердить',
    'beeline.payment.confirmation.decline_text': 'Отмена',
    'beeline.payment.confirm.user.agreement.text': 'Переходя к оплате вы соглашаетесь с',
    'beeline.payment.confirm.user.agreement.link': 'пользовательским соглашением',
    'navsegda': 'Навсегда',
    'sapogiskorohodi.snjati': 'Сапоги-скороходы сняты',
    'dalshe': 'Дальше',
    'prinjat': 'ПРИНЯТЬ',
    'empty.nm': 'Название не может быть пустым!',
    'a.poka.davay.ne.budem.terjat.ni.minuti': 'А пока давай не будем терять ни минуты и отправимся в путь, я покажу тебе что и как.',
    'smenit': 'СМЕНИТЬ',
    'zadat': 'ЗАДАТЬ',
    'informatsija': 'ИНФОРМАЦИЯ:',
    'ne.uchastvuet.v.boju': 'Не участвует в бою',
    'poluchit.seychas': 'ПОЛУЧИТЬ СЕЙЧАС',
    'bonusi': 'БОНУСЫ: ',
    'postroit': 'ПОСТРОИТЬ',
    'details': 'ДЕТАЛИ',
    'igrok.ponizhen.do.uchastnika': 'Игрок понижен до участника',
    'vkljuchit.zvuk': 'ВКЛЮЧИТЬ ЗВУК',
    'peremeschenie': 'ПЕРЕМЕЩЕНИЕ',
    'ustavshiy.magkuznets.proiznosit': 'Уставший маг-кузнец произносит: Увы, мои силы иссякли! Разыщите другого кузнеца',
    'pochta': 'ПОЧТА',
    'vkljuchit.v.otrjad': 'Включить в отряд',
    'popolnenija.kr..kristalli': 'Пополнения. Кр. кристаллы: ',
    'novichok': 'Новичок',
    'drakon': 'ДРАКОН',
    'otlichno.schit.teper.daet.k.zaschite': 'Отлично! Щит теперь дает +3 к защите. Давай вернемся к дороге.',
    'legkoe': 'ЛЕГКОЕ',
    'brosil': ' бросил ',
    'zadanie.vipolneno': 'ЗАДАНИЕ ВЫПОЛНЕНО',
    'sled.ur.bracket': ' (СЛЕД. УР. ',
    'kto.budet.luchshim.iz.luchshih': 'КТО БУДЕТ ЛУЧШИМ ИЗ ЛУЧШИХ?',
    'pervaja.smena.oboydetsja.besplatno': 'Первая смена обойдется бесплатно. Все последующие смены пола будут стоить ',
    'dari': 'ДАРЫ',
    'artifacts': 'АРТЕФАКТЫ',
    'opit': 'Опыт: ',
    'good.points': 'Очки Порядка',
    'evil.points': 'Очки Тени',
    'tickets': 'Билеты',
    'zahodite.v.igru.zavtra.i.poluchite': 'ЗАХОДИТЕ В ИГРУ ЗАВТРА И ПОЛУЧИТЕ ',
    'vstupit': 'ВСТУПИТЬ',
    'a.teper.viberem.geroja.kotoriy.poluchit.': 'А ТЕПЕРЬ ВЫБЕРЕМ ГЕРОЯ, КОТОРЫЙ ПОЛУЧИТ +',
    'zvuk.vkljuchen': 'ЗВУК ВКЛЮЧЕН',
    'zdorove.cherez.min': 'ЗДОРОВЬЕ +1 ЧЕРЕЗ 1МИН',
    'zdorove.cherez': 'ЗДОРОВЬЕ +1 ЧЕРЕЗ ',
    'vi.poluchaete': 'ВЫ ПОЛУЧАЕТЕ',
    'razblokirovat': 'РАЗБЛОКИРОВАТЬ',
    'mifril': 'Мифрил',
    'write.message': 'Написать...',
    'kuznitsa': 'КУЗНИЦА',
    'predmeti': 'ПРЕДМЕТЫ',
    'smenit.pol': 'СМЕНИТЬ ПОЛ',
    'smenit.sposob.oplaty': 'СПОСОБ ОПЛАТЫ',
    'smena.sposoba.oplaty': 'СМЕНА СПОСОБА ОПЛАТЫ',
    'vi.pitaetes.smenit.sposob.oplaty': 'ВЫБЕРИТЕ ПОДХОДЯЩИЙ СПОСОБ ОПЛАТЫ',
    'viberite.sposob.oplaty': 'Выберите подходящий способ оплаты',
    'current.payment.method': 'ТЕКУЩИЙ СПОСОБ:',
    'payment.method.recommended': '(РЕКОМЕНДУЕТСЯ)',
    'xsolla.label': 'XSOLLA',
    'robokassa.label': 'ROBOKASSA',
    'snizhenie.ataki.protivnika': '-СНИЖЕНИЕ АТАКИ ПРОТИВНИКА',
    'otpravit.pismo': 'ОТПРАВИТЬ ПИСЬМО',
    'krasn.krist.': 'Красн. крист.',
    'proigrali.nichego.chtobi.luchshe.srazhatsja.davay.podberem.etot.schit': 'Проиграли? Ничего! Чтобы лучше сражаться, давай подберем этот щит.',
    'lider': 'Лидер',
    'dialog': 'ДИАЛОГ',
    'supp.info': 'Это сообщение для службы Технической поддержки игры "Зазеркалье". Опишите, пожалуйста, свою проблему или вопрос подробно, четко и ясно.',
    'ozhidayte.priglashenija.v.klan.': 'ОЖИДАЙТЕ ПРИГЛАШЕНИЯ В КЛАН.',
    'slabosti': 'СЛАБОСТИ: ',
    'sdatsja': 'СДАТЬСЯ',
    'vipolneno': 'ВЫПОЛНЕНО: ',
    'dop.uron.nekromantam': '-ДОП. УРОН НЕКРОМАНТАМ',
    'do.kontsa': 'ДО КОНЦА: ',
    'dop.uron.jascheram': '-ДОП. УРОН ЯЩЕРАМ',
    'igrok.prigleshen.v.klan': 'Игрок приглешен в клан',
    'uyti.v.otstavku': 'УЙТИ В ОТСТАВКУ',
    'doplatit.izumrudami': 'ДОПЛАТИТЬ ИЗУМРУДАМИ?',
    'use.offer': 'ВОСПОЛЬЗУЙТЕСЬ АКЦИЕЙ В МАГАЗИНЕ!',
    'zaschita.klana': 'ЗАЩИТА КЛАНА ',
    'otlichno.geroy.poluchit': 'Отлично, герой получит ',
    'vozmozhnost.vstupit.v.klan.otkroetsja.s': 'ВОЗМОЖНОСТЬ ВСТУПИТЬ В КЛАН ОТКРОЕТСЯ С ',
    'vibor.geroja': 'ВЫБОР ГЕРОЯ',
    'otlichno.ah.chut.ne.propustil.eta.kuchka.s.kristallami.nam.tozhe.nuzhna': 'Отлично! Ах, чуть не пропустил: эта кучка с кристаллами нам тоже нужна!',
    'izuchit': 'ИЗУЧИТЬ',
    'dobro.pozhalovat.na.arenu': 'ДОБРО ПОЖАЛОВАТЬ НА АРЕНУ!',
    'priglasit.v.klan': 'ПРИГЛАСИТЬ В КЛАН',
    'vnesti': 'внести',
    'pozhertvovat': 'ПОЖЕРТВОВАТЬ',
    'zavidev.vas.kuznetsmag.ozhivljaetsja': 'Завидев вас кузнец-маг оживляется и молвит: Добро пожаловать ко мне в кузницу!',
    'y.uroven.prjamo.seychas': '-й уровень прямо сейчас',
    'bil.v.igre': 'Был в игре: ',
    'minute.dot': 'м.',
    'smenit.besplatno': 'Сменить бесплатно?',
    'golem': 'ГОЛЕМ',
    'ponizit.do.uchastnika': 'ПОНИЗИТЬ ДО УЧАСТНИКА',
    'skidka.na.pervogo.geroja': 'Скидка на первого героя!',
    'vihod': 'ВЫХОД',
    'magazin': 'МАГАЗИН',
    'one.chas': '1 час',
    'gruppa': 'ГРУППА',
    'priobretenie.sputnika': 'ПРИОБРЕТЕНИЕ СПУТНИКА',
    'priobretenie.sputnikov': 'ПРИОБРЕТЕНИЕ СПУТНИКОВ',
    'podderzhka': 'ПОДДЕРЖКА',
    'dlja.uluchshenija.neobhodima.pobeda': 'Для улучшения необходима победа надо монстром с уровнем  ',
    'hero.can.improve.forces.up.to.rank': 'Герой может улучшить все дары до ранга ',
    'vse.molotki.zaniati': 'Все волшебные молотки заняты улучшениями!',
    'dlja.uluchshenija.neobhodim': 'Для улучшения необходим ',
    'initsializatsija.proshla.uspeshno': 'Инициализация прошла успешно',
    'sinie.krist': 'Синие крист.',
    'vi.pitaetes.smenit.pol.vashego.personazha': 'Вы пытаетесь сменить пол вашего персонажа',
    'v.boy': 'В БОЙ',
    'zadanie': 'ЗАДАНИЕ',
    'dop.uron.vsem.vragam': '-ДОП. УРОН ВСЕМ ВРАГАМ',
    'k.doroge': 'К дороге',
    'uvelichte.svoy.otrjad.vplot.do..geroev': 'Увеличьте свой отряд вплоть до 6 героев!',
    'mag': 'МАГ',
    'vzjat': 'ВЗЯТЬ',
    'zadanija': 'ЗАДАНИЯ',
    'uchastnik': 'Участник',
    'ezhednevnoe': 'ЕЖЕДНЕВНОЕ',
    'kogda.geroy.naberet': '[color',
    'neobhodimo': 'НЕОБХОДИМО',
    'kolichestvo.shagov.v.snezhnom.mire.ogranicheno.n': 'Количество шагов в снежном мире ограничено.\n',
    'voyti': 'Войти',
    'kapituljatsija': 'КАПИТУЛЯЦИЯ',
    'ur.dot': 'Ур. ',
    'idet.uluchshenie': 'Идет улучшение',
    'idet.izuchenie': 'Идет изучение',
    'proigral': 'проиграл.',
    'takzhe.obratite.vnimanie.na.moment': 'Также обратите внимание: на момент начала циферки могут немного измениться в зависимости от уровня и количества участников.',
    'one.den': '1 день',
    'novaja': 'новая',
    'pomogite.svoemu.klanu.uchastvuyte.v.komandnom.zadanii': 'Помогите своему клану. Участвуйте в командном задании. Выполнить его можно лишь действуя сообща.',
    'igrok': 'Игрок: ',
    'igrokk': 'Игрок',
    'igroka': 'игрока',
    'ponizit.do.novichka': 'ПОНИЗИТЬ ДО НОВИЧКА',
    'uroven': 'Уровень',
    'ur.': 'Ур.',
    'viberite.na.kogo.zamenit': 'ВЫБЕРИТЕ, НА КОГО ЗАМЕНИТЬ?',
    'mi.nashli.i.sobrali.resursi.eto.ochen.horosho': 'Мы нашли и собрали ресурсы! Это очень хорошо, они нам пригодятся. А пока продолжим наш путь.',
    'nagrada.klanu': 'НАГРАДА КЛАНУ: ',
    'nedostatochno.resursov': 'Недостаточно ресурсов',
    'nedostatochno.biletov': 'Недостаточно билетов',
    'povorot': 'ПОВОРОТ',
    'slozhnost.zadanija': 'СЛОЖНОСТЬ\nЗАДАНИЯ',
    'arena.esche.ne.otkrilas.podozhdite.nemnogo': 'Арена еще не открылась, подождите немного...',
    'postroyki': 'ПОСТРОЙКИ',
    'ataku': 'Атаку',
    'summarniy.prirost': 'СУММАРНЫЙ ПРИРОСТ',
    'prirost': 'ПРИРОСТ',
    'chto.takoe.klan': 'ЧТО ТАКОЕ КЛАН?',
    'sozdat.klan': 'СОЗДАТЬ КЛАН',
    'er.nm.lng': 'Никнейм длинноват. Максимум ',
    'er.nm.same': 'Вы ввели текущий никнейм ',
    'zabanit.igroka': 'ЗАБАНИТЬ ИГРОКА',
    'stroitsja': 'СТРОИТСЯ',
    'ur.large.dot': 'УР.',
    'protivnik': 'Противник',
    'change.of.your.position': 'Изменение вашей позиции',
    'smena.pola': 'СМЕНА ПОЛА',

    'takim.obrazom.za.boy.mozhno.poluchit': 'Таким образом, за бой можно получить суммарно до 6 очков в зависимости от количества противников.[/color]\n\n',
    'vi.pokinuli.klan': 'Вы покинули клан',
    'kazna.popolnena': 'КАЗНА ПОПОЛНЕНА',
    'vidish.eto.schit.kotoriy.mi.nashli': 'Видишь, это щит, который мы нашли. Уже хорошо, но мы можем улучшить его. Для этого кликни на щит.',
    'registratsija': 'РЕГИСТРАЦИЯ!',
    'igrok.izgnan.iz.klana': 'Игрок изгнан из клана',
    'igrok.povishen.do.predstavitelja': 'Игрок повышен до представителя',
    'kristalli': 'Кристаллы',
    'ups': 'УПС',
    'no.forces.to.learn': 'НЕТ ДАРОВ ДЛЯ ИЗУЧЕНИЯ.',
    'no.forces.to.learn2': 'Все возможные дары героя уже изучены.',
    'ups.warlock': 'Чернокнижник не может улучшить ранг. Однако любой дар изучается сразу вторым рангом.',
    'ups.titan': 'Титан не может улучшить ранг. Однако любой дар изучается сразу третьим рангом.',
    'ups.angel': 'Ангел не может улучшить ранг. Однако любой дар изучается сразу четвертым рангом.',
    'ups.archangel': 'Архангел не может улучшить ранг. Однако любой дар изучается сразу пятым рангом.',
    'ups.archititan': 'Архититан не может улучшить ранг. Однако любой дар изучается сразу четвертым рангом.',
    'ups.yotun': 'Йотун не может улучшить ранг. Однако любой дар изучается сразу пятым рангом.',
    'ups.asg.phoenix': 'Асгардианский феникс не может улучшить ранг. Однако любой дар изучается сразу пятым рангом.',
    'ups.warlock.short' : 'Чернокнижник не прокачивает ранг.',
    'ups.titan.short': 'Титан не прокачивает ранг.',
    'ups.archititan.short': 'Архититан не прокачивает ранг.',
    'ups.angel.short': 'Ангел не прокачивает ранг.',
    'ups.archangel.short': 'Архангел не прокачивает ранг.',
    'ups.yotun.short': 'Йотун не прокачивает ранг.',
    'ups.asg.phoenix.short': 'Асгардианский Феникс не прокачивает ранг.',
    'kupit': 'КУПИТЬ',
    'spisok.sushestv': 'СПИСОК СУЩЕСТВ',
    'five.minut': '5 минут',
    'skidki.na.vseh.geroev': 'Скидки на всех героев!',
    'viberi.kto.iz.geroev.poluchit.bonus': 'Выбери, кто из героев получит бонус',
    'uvelichivaet.opit.poluchaemiy.za.boy.vsem.uchastnikov.klana': 'УВЕЛИЧИВАЕТ ПОЛУЧАЕМЫЙ ЗА БОЙ ОПЫТ ДЛЯ ВСЕХ УЧАСТНИКОВ КЛАНА.',
    'no.mon': 'Недостаточно средств для покупки',
    'bank.izumrudov.dostupen.s': 'Банк изумрудов доступен с ',
    'ne.hvataet': 'НЕ ХВАТАЕТ',
    'umensh..uron.vragamkrome.nekr.': '-УМЕНЬШ. УРОН ВРАГАМ(КРОМЕ НЕКР.)',
    'nanosit.uron.atakujuschemu': '-НАНОСИТ УРОН АТАКУЮЩЕМУ',
    'dial.l': 'СПИСОК ДИАЛОГОВ',
    'do.kontsa.predlozhenija': 'ДО КОНЦА ПРЕДЛОЖЕНИЯ',
    'no.msg': 'Нет сообщений',
    'smiley.img': 'Изображение смайла',
    'smiley': 'Смайл',
    'answer': 'Цитировать',
    'copy.msg': 'Копировать',
    'delete.message': 'Удалить',
    'forw.message': 'Пересланное сообщение',
    'max.message.len': 'Максимальная длинна сообщения составляет 1000 символов.',
    'chlen': 'Член',
    'dlja': 'ДЛЯ ',
    'hochet.imenno.v.vash.klan': 'Хочет именно в Ваш клан',
    'uspey.kupit': 'УСПЕЙ КУПИТЬ!',
    'komandnoe.zadanie.segodnja': 'КОМАНДНОЕ ЗАДАНИЕ СЕГОДНЯ',
    'vi.poluchaete.noviy.uroven': 'Вы получаете новый уровень!',
    'one.nedelja': '1 неделя',
    'brac.boev': '),   БОЕВ: ',
    'izumrudov': ' изумрудов',
    'zdes.igroki.srazhajutsja.drug.s.drugom': 'Здесь игроки сражаются друг с другом.',
    'na.arenu': 'НА АРЕНУ!',
    'mozhno.ispolzovat.v.boju': 'МОЖНО ИСПОЛЬЗОВАТЬ В БОЮ',
    'ispolzovat': 'ИСПОЛЬЗОВАТЬ',
    'snjat.sapogi.skorohodi': 'СНЯТЬ САПОГИ СКОРОХОДЫ',
    'po.kubkam': 'По кубкам',
    'by.arena.position': 'По арене',
    'pobedil': 'победил.',
    'vas.priglashajut.v.klan': 'Вас приглашают в клан',
    'etot.slot.poka.ne.dostupen': 'Этот слот пока не доступен.',
    'skidki.na.vseh.geroev.v.magazine': 'СКИДКИ НА ВСЕХ ГЕРОЕВ В МАГАЗИНЕ!',
    'snizh.zaschiti.vraga.pri.udarah': '-СНИЖ. ЗАЩИТЫ ВРАГА ПРИ УДАРАХ',
    'otpravitsja.v.snezhniy': 'Отправиться в снежный мир можно не чаще, чем раз в ',
    'uluchshit.do.urovnja': 'Улучшить до уровня ',
    'uluchshit.do.ranga': 'Улучшить до ранга ',
    'podrobnee.o.rangah': 'подробнее о рангах',
    'explore.new.force': 'Изучить новую силу',
    'poluchaem': 'ПОЛУЧАЕМ',
    'otsutstvujut': '-ОТСУТСТВУЮТ',
    'viberi.kto.iz.geroev.poluchit.predmet': 'ВЫБЕРИ, КТО ИЗ ГЕРОЕВ ПОЛУЧИТ ПРЕДМЕТ',
    'pokinut.klan': 'ПОКИНУТЬ КЛАН',
    'v.snezhniy.mir': 'В СНЕЖНЫЙ МИР',
    'zaschitu': 'Защиту',
    'darim.vam.ezhednevniy.podarok': 'ДАРИМ ВАМ ЕЖЕДНЕВНЫЙ ПОДАРОК',
    'zagruzka': 'ЗАГРУЗКА',
    'snachala.viberite.artefakt.dlja.perekovki.': 'Сначала выберите артефакт для перековки.',
    'spets.predlozhenie': 'СПЕЦ. ПРЕДЛОЖЕНИЕ!',
    'hochet.v.ljuboy.klan': 'Хочет в любой клан',
    'vi.ushli.v.otstavku': 'Вы ушли в отставку',
    'slozhnost.skorrektirovana': 'Сложность скорректирована',
    'obnovit': 'ОБНОВИТЬ',
    'net.svobodnogo.mesta.dlja.artefakta': 'НЕТ СВОБОДНОГО МЕСТА ДЛЯ АРТЕФАКТА',
    'odin.iz.starih.geroev.budet.iskljuchen.iz.otrjada': 'ОДИН ИЗ СТАРЫХ ГЕРОЕВ БУДЕТ ИСКЛЮЧЕН ИЗ ОТРЯДА',
    '4ast.budet.iskl': 'ЧАСТь СТАРЫХ ГЕРОЕВ БУДЕТ ИСКЛЮЧЕНА ИЗ ОТРЯДА',
    'v.profil': 'В ПРОФИЛЬ',
    'ne.hvataet.izumrudov': 'Не хватает изумрудов!',
    'personazh.polnostju.zdorov': 'Персонаж полностью здоров',
    'prochee': 'ПРОЧЕЕ',
    'vosstaet.posle.smerti': '-ВОССТАЕТ ПОСЛЕ СМЕРТИ',
    'er.ps.lng': 'Пароль длинноват. Максимум ',
    'peredat': 'Передать',
    'gotovi.priobresti.sputnika': 'ГОТОВЫ ПРИОБРЕСТИ СПУТНИКА?',
    'ur.klassa': ', УР. КЛАССА: ',
    'zazerkale.mir.geroev.i.volshebnikov.monstrov.i.tayn.': 'Зазеркалье - мир героев и волшебников, монстров и тайн. ',
    'oshibka.initsializatsii': 'Ошибка инициализации',
    'kazhdiy.raz.kogda.geroy.dobivaet.vraga': '[size',
    'postroyki.klana': 'ПОСТРОЙКИ КЛАНА',
    'zaversheno.uluchshenie': 'ЗАВЕРШЕНО УЛУЧШЕНИЕ',
    'zaversheno.uluchshenie.dara': 'ЗАВЕРШЕНО УЛУЧШЕНИЕ ДАРА',
    'teper.vladeet': 'теперь владеет ',
    'teper.vladeut': 'теперь владеют ',
    'novim.darom': 'новым даром.\nТак держать!',
    'novimi.darami': 'новыми дарами.\nТак держать!',
    'k.klanu': 'К КЛАНУ',
    'jascher': 'ЯЩЕР',
    'uvi.sloti.vseh.ostalnih.geroev.zanjati': 'УВЫ, СЛОТЫ ВСЕХ ОСТАЛЬНЫХ ГЕРОЕВ ЗАНЯТЫ!',
    'horosho': 'ХОРОШО',
    'ok': 'OK',
    'predmet.uspeshno.priobreten': 'Предмет успешно приобретен!',
    'predmeti.uspeshno.priobreteni': 'Предметы успешно приобретены!',
    'kontrataka': '-КОНТРАТАКА',
    'vikljuchit.zvuk': 'ВЫКЛЮЧИТЬ ЗВУК',
    'er.ps.n.mtch': 'Ой! Введенные пароли не совпадают',
    'confirm': 'ПОДТВЕРЖДАЕМ?',
    'rang': 'РАНГ',
    'ranga': 'РАНГА',
    'hero.rang': 'РАНГ ГЕРОЯ',
    'can.improve.forces': 'МОЖНО УЛУЧШИТЬ ДАРЫ!',
    'force.ranks': 'РАНГИ ДАРА',
    'forces': 'ДАРЫ',
    'reforge': 'ПЕРЕКОВКА АРТЕФАКТОВ',
    'clan.max.mem': 'В КЛАНЕ МАКСИМУМ УЧАСТНИКОВ',
    'home': 'ПОРА ДОМОЙ',
    'fights': 'БОЕВ',
    'fights.in': 'БОЯ ЧЕРЕЗ',
    'go.level': '-ГО УРОВНЯ',
    'heroes': 'ГЕРОЕВ',
    'items.gen': 'ПРЕДМЕТОВ',
    'forces.gen': 'ДАРОВ',
    'force.gen': 'ДАРА',
    'max': 'МАКС.',
    'edit': 'РЕДАКТИРОВАТЬ',
    'block': 'Заблокировать',
    'init.txt': 'ИСХОДНЫЙ ТЕКСТ',
    'force': 'ДАР',
    'rank': 'РНГ.',
    'editing': 'РЕДАКТИРОВАНИЕ',
    'save': 'СОХРАНИТЬ',
    'clan.cr': 'СОЗДАНИЕ КЛАНА',
    'clan.nm': 'Придумайте название клану',
    'ent.nm': 'Введите название',
    'create': 'СОЗДАТЬ',
    'name': 'ИМЯ',
    'pass': 'ПАРОЛЬ',
    'log.in': 'Войти',
    'symbols': 'символов',
    'inv.nm': 'Придумайте никнейм',
    'inv.ps': 'Придумайте пароль',
    'rpt.ps': 'Повторите  пароль',
    'mail.or.phn': 'Почтовый ящик или телефон (необязательно)',
    'nm.chng': 'СМЕНА ИМЕНИ',
    'mail.or.phn.chng': 'ПОЧТА ИЛИ ТЕЛЕФОН',
    'passw.chng.fill': 'Нужно заполнить все поля',
    'passw.chng.header': 'СМЕНА ПАРОЛЯ',
    'passw.chng.info': ' Здесь вы можете установить новый пароль. После смены пароля потребуется заново войти в игру',
    'passw.chng.curr': 'Текущий пароль',
    'passw.chng.new': 'Новый пароль',
    'passw.chng.new.rpt': 'Повторите новый пароль',
    'ldng': 'Загрузка ...',
    'lan': 'ЯЗЫК',
    'switch.lang': 'Выбранный язык: РУССКИЙ. Переключить на английский?',
    'select.lang': 'Выбор подходящего языка:',
    'logo-class': 'aof-logo',

    'gems.mul.gen': 'изумрудов',
    'from': 'с',
    'fromC': 'С',
    'discount.used':'Цена со скидкой',
    'to': 'по',
    'lev.gen': 'уровня',
    'news.rang.title': 'РАНГ!',
    'news.rang.subtitle': 'ГЕРОЯМ ДОСТУПНА ПРОКАЧКА РАНГА!',
    'news.rang.1': 'Каждый герой теперь получает личный опыт. Набрав нужное количество опыта, герой получит новый ранг.',
    'news.rang.2': 'Ранг поднимается долго. Однако тем, кому удастся это сделать, достанутся усиленные бонусы для их героев.',
    'news.force.title': 'ДАРЫ!',
    'news.force.subtitle': 'ВАШИМ ГЕРОЯМ ДОСТУПНЫ ДАРЫ!',
    'news.force.1': 'Каждый герой теперь может получить дар - бонус другого класса.',
    'news.force.2': 'Например, человек, получив дар дракона, сможет атаковать дважды, при этом снижая защиту врага.',
    'news.force.40.title': 'Страница героя',
    'news.force.40.1': 'Добро пожаловать на обновленную страницу героя!',
    'news.force.40.2': 'Возможностей теперь больше, а навигация удобнее.',
    'news.force.500.1': 'Вау, у нас тут всё изменилось!',
    'news.force.500.2': 'Артефакты остались привычными.',
    'news.force.500.3': 'А вот дары... Теперь многие герои смогут улучшать ранги даров!',
    'news.force.500.4': 'Да-да, вот так! Сейчас всё расскажем!',
    'news.force.500.5': 'Перейдя на вкладку "Дары", Вы увидите вокруг героя его дары - как изученные, так и те, которые можно изучить.',
    'news.force.500.6': 'Дары, что можно изучить, помечены зеленым замочком.',
    'news.force.500.7': 'Дары, что можно улучшить, помечены двумя стрелочками.',
    'news.force.500.8': 'Помните - улучшение даров занимает время.',
    'news.force.500.9': 'При том, одновременно можно улучшать лишь два дара.',
    'news.force.500.10': 'Поэтому выбирайте с умом и улучшайте лишь самые нужные дары!',
    'news.force.500.11': 'Разные герои могут улучшать дары до разных уровней.',
    'news.force.500.12': 'Вот тут то и начинается самое интересное: ведь теперь ценнее тот герой, что может улучшить дары до более высокого ранга.',
    'news.force.500.13': 'Так, например, человек максимально может улучшить дары до 5-го ранга, асгардинские фениксы - до 6-го. Роккара - до 7-го, а великая Нук-Серра - аж до эпического 8-го ранга.',
    'news.force.500.14': 'Но хватит слов, давайте играть! Вперед, в великий путь!',
    'news.rf.title': 'РАНГ И ДАРЫ!',
    'news.rf.subtitle': 'ДОСТУПНЫ ПРОКАЧКА РАНГА И ДАРЫ!',
    'news.rf.1': 'Каждый герой теперь получает личный опыт, и может повысить свой ранг.',
    'news.rf.2': 'Также герой может получить дар другого класса. Например, человек, получив дар дракона, сможет атаковать дважды и снижать защиту врага.',
    'forces.are.cool': '*Дополнить своих героев новыми силами особенно эффективно, т.к. при изучении дополнительной силы герой получает лишь ее положительные стороны. Например, изучение силы некроманта не сделает героя уязвимым для джиннов. Однако лишь для основного класса доступна прокачка ранга.',
    'clan.resign': 'Вы уверены, что хотите перестать быть лидером клана? Лидерство перейдет самому достойному соклановцу.',
    'clan.leave': 'Вы уверены, что хотите покинуть этот клан?',
    'do.you.want.to.buy': 'Вы хотите купить ',
    'insufficient.emeralds': 'Недостаточно изумрудов',
    'buy.hammer.agitation': 'Купите волшебный молоточек, и улучшайте аж {0} дара одновременно!',
    'buy.hammer.agitation2': 'Купите волшебный молоточек, и улучшайте аж {0} даров одновременно!',
    'd.hammer.for': '-й волшебный молоток за ',
    'th.hammer.for':  '-й волшебный молоток за ',
    'pokupka.molotka':  'Покупка молотка',
    'clan.txt.revert': 'Вы уверены, что хотите вернуть исходный текст? Все изменения, сделанные в тексте, будут потеряны.',
    'clan.b.started': 'Постройка начата',
    'clan.b.completed': 'Постройка завершена',
    'news.p2.title': 'ПРОДОЛЖЕНИЕ!',
    'news.p2.subtitle': 'ДОБРО ПОЖАЛОВАТЬ ВО 2-Ю ЧАСТЬ!',
    'news.p2.1': 'Продолжение игры, наконец то готово! Портал запущен, скорее - в мир Солнечных драконов!',
    'news.p2.2': 'Вскоре вы встретите поворот на портал. Успехов вам в игре. Спасибо, что вы с нами!',
    'th.place': '-е место',
    'hour.dot': 'ч.',
    'sec.dot': 'с.',
    'rang.1': 'Для Вас теперь доступна прокачка ранга героя!',
    'rang.2': 'Каждый раз когда герой добивает врага, он получает очко личного опыта.',
    'rang.3': 'Когда герой наберет нужное число очков, он получит новый ранг и улучшенные классовые бонусы (сохранив при этом все предыдущие).',
    'rang.4': 'Ранг поднимается долго. Однако тем, кому удастся это сделать, достанутся сильные бонусы для их героев.',
    'th.rang.now': '-й ранг сейчас?',
    'of': 'из',
    'pict.not.sup': 'картинки пока не поддерживаются',
    'message.copied': 'Сообщение скопировано',
    'exp.t.lev': 'опыта до ур.',
    'red.cr.gen': 'красных кристалла',
    'red.cr.mul.gen': 'красных кристаллов',
    'blue.cr.gen': 'синих кристалла',
    'blue.cr.mul.gen': 'синих кристаллов',
    'gold.gen': 'золота',
    'exit.sure': 'Вы уверены, что хотите выйти из игры?',
    'moder': 'МОДЕРАТОР',
    'admin': 'АДМИНИСТРАТОР',
    'god': 'РАЗРАБОТЧИК',
    'good.fellow': 'ХОРОШИЙ ИГРОК',
    'recieves': 'получает',
    'challenged.female': 'бросила',
    'you.lost': 'проиграли.',
    'she.lost': 'проиграла.',
    'she.won': 'победила.',
    'best.v.lev': 'лучшая победа: ур.',
    'clan.exp': 'опыта в клане',
    'clan.fights': 'боев в клане',
    'last.ent': 'Посл. вход',
    'at.y.service': 'Жду ваших указаний.',
    'req.gain': 'требуется собрать',
    'bl.cryst': 'син. кристаллы',
    'hrs': 'часов',
    'best.mul.gen': 'ЛУЧШИХ',
    'beast.gen': 'ЗВЕРЯ',
    'demon.gen': 'ДЕМОНА',
    'dragon.gen': 'ДРАКОНА',
    'gin.gen': 'ДЖИННА',
    'golem.gen': 'ГОЛЕМА',
    'human.gen': 'ЧЕЛОВЕКА',
    'lizard.gen': 'ЯЩЕРА',
    'elf.gen': 'ЭЛЬФА',
    'mag.gen': 'МАГА',
    'necro.gen': 'НЕКРОМАНТА',
    'serpent.gen': 'ЗМЕЯ',
    'troll.gen': 'ТРОЛЛЯ',
    'phoenix.gen': 'ФЕНИКСА',
    'can.imp': 'МОЖНО УЛУЧШИТЬ',
    'th.lev.2.batl': '-ГО УРОВНЯ, ЧТОБЫ СРАЗИТЬСЯ!',
    'disc.to': 'СКИДКА ДО',
    'open.slot.with.disc': 'ОТКРЫТИЕ СЛОТА СО СКИДКОЙ!',
    'pcnt.for.slot': '% НА ОТКРЫТИЕ СЛОТА В ПОДАРОК!',
    'cl.ftrs': 'ОСОБЕННОСТИ КЛАССА',
    'your.active': 'ВАШИ АКТИВНЫЕ',
    'boosters2': 'УСИЛЕНИЯ',
    'imp.cost': 'СТОИМОСТЬ УЛУЧШЕНИЯ',
    'bld': 'СТРОИТЕЛЬСТВА',
    'imp.g': 'УЛУЧШЕНИЯ',
    'imp': 'УЛУЧШИТЬ',
    'imp.ing': 'УЛУЧШАЕТСЯ',

    'reward_x10_for_win.info': 'В случае победы в следующем бою вы получите в 10 раз больше ресурсов',
    'elixir_for_win.info': 'В случае победы в следующем бою вы получите 250 синих кристаллов',
    'mithril_for_win.info': 'В случае победы в следующем бою вы получите 5 изумрудов',
    'attack_for_win.info': 'В случае победы в следующем бою герои получат +10 атаки',
    'defence_for_win.info': 'В случае победы в следующем бою герои получат +10 защиты',
    'attack-half-to-all.info': 'Все герои теряют половину атаки в следующем бою',
    'defence-half-to-all.info': 'Все герои теряют половину защиты в следующем бою',
    'attack-double-to-all.info': 'Все герои получают удвоенную атаку в следующем бою',
    'anti-warlock-power.info': 'Все герои получают удвоенную атаку и защиту в следующем бою',
    'attackAll05.info': 'Атака и защита всех героев уменьшены на 25% (пока вы не снимете проклятье)',
    'defence-double-to-all.info': 'Все герои получают удвоенную защиту в следующем бою',
    'gin_defence.info': 'В следующем бою кристалл спасения дарует вашему джинну свою защиту',
    'beast_defence.info': 'В следующем бою кристалл спасения дарует вашим зверям свою защиту',
    'mag_defence.info': 'В следующем бою кристалл спасения дарует вашим магам свою защиту',
    'human_defence.info': 'В следующем бою кристалл спасения дарует человеку свою защиту',
    'dragon_defence.info': 'В следующем бою кристалл спасения дарует вашим драконам свою защиту',
    'necro_defence.info': 'В следующем бою кристалл спасения дарует вашим некромантам свою защиту',
    'charmMarker.info': 'В следующем бою одно существо противника будет сражатся на вашей стороне',
    'charm2.info': 'В следующем бою противники атакуют друг друга',
    'blueElvenFlowerMarker.info': 'Сила эльфийского цветка приносит вам 100 единиц защиты в следующем бою',
    'redElvenFlowerMarker.info': 'Сила эльфийского цветка приносит вам 100 единиц атаки в следующем бою ',
    'yellowElvenFlowerMarker.info': 'Сила эльфийского цветка приносит вам 10 единиц здоровья в следующем бою ',
    'stealCurseMarker.info': 'Ваш моральный дух в следующем бою  ниже обычного',
    'witchLuckMarker.info': 'Духи, ниспосланные ведьмой, помогают вам в следующем бою ',
    'witchCurseMarker.info': 'Проклятые ведьмой вы чувствуете сильное недомогание в следующем бою ',
    'threePowerCone.info': 'Сила могучего древа увеличивает защиту героев вдвое в следующем бою ',
    'fairyMarker.info': 'Добрые чары феи помогают вам в следующем бою ',
    'snowflakeMarker.info': 'Снежинка победителя ослабляет вашего противника в следующем бою ',
    'p6tavPotionMarker.info': 'Зелье из таверны ослабляет вашего противника в следующем бою ',
    'cloverMarker.info': 'Клевер удачи наделяет вас удачей в следующем бою ',
    'luckyCat.info': 'Котик наделил Вас удачей на ближайший бой ',
    'healthPlus3Marker.info': 'Цветки бессмертия даруют героям +3 здоровья в следующем бою ',
    'kettle.info': 'Отвар дракона дарует +10 защиты всем героям в следующем бою ',
    'healthPlus25.info': 'Волшебный водоворот дарует +25 здоровья всем вашим существам в следующем бою ',
    'summonCopyMarker.info': 'Волшебная пыль привызывает копию вашего случайного героя в следующем бою ',
    'deathCrystal.info': 'Кристалл смерти уничтожает случайного противника в следующем бою ',
    'idolOfDestruction.info': 'Идол разрушения уничтожает случайное существо в следующем бою ',
    'mushDefence500marker.info': 'Гриб дарует +5000 защиты вашему случайному существу в следующем бою ',
    'mushAttack500marker.info': 'Гриб дарует +500 атаки вашему случайному существу в следующем бою ',
    'serpentForce.info': 'Гриб змия наделяет силой змия случайного героя в следующем бою ',
    'superDefence.info': 'Защита случайного героя увеличена в 5 раз, но уменьшена атака в 2 раза в следующем бою ',
    'mushroomMarker.info': 'Атака всех героев увеличена в 2 раза, но уменьшана защита до 1 в следующем бою',
    'mushroomMarker2.info': 'Гриб шамана дарует +20 защиты и -10 атаки всем героям в следующем бою ',
    'looseForcePhoenix.info': 'Ваши противники теряют силу феникса в следующем бою ',
    'looseForceDeath.info': 'Ваши противники теряют силу смерти в следующем бою ',
    'looseForceWitch.info': 'Ваши противники теряют силу ведьмы в следующем бою ',
    'looseForceMag.info': 'Ваши противники теряют силу мага в следующем бою ',
    'exp_x10.info': 'В случае победы в следующей битве вы получите десятикратный опыт',
    'anyHeroAttackX3.info': 'Атака вашего или вражеского случайного существа в следующем бою будет утроена',
    '3_vict_sphere.info': 'Победите в ближайших трех боях, и получите +100 к атаке всех героев',
    'new_level_on_victory.info': 'В случае победы в ближайшем бою, вы сразу получите новый уровень',

    'pct.for.lev': '% ЗА КАЖДЫЙ УРОВЕНЬ ПОСТРОЙКИ',
    'rep.for.lev': '% ОТРАЖЕННЫХ УДАРОВ ЗА КАЖДЫЙ УРОВЕНЬ ПОСТРОЙКИ',
    'factory.for.lev': '% ЦЕННОСТь ПОДАРКА ЗА КАЖДЫЙ УРОВЕНЬ ПОСТРОЙКИ',
    'edit.clan.txt': 'Отредактируйте текст по своему усмотрению! Разместите здесь правила своего клана, обращение к игрокам, и, в общем, всё, что захотите.',
    'con.fail': 'УПС! ИГРА НЕДОСТУПНА. СКОРЕЕ ВСЕГО ИДЕТ ОБНОВЛЕНИЕ',
    'try.ltr': 'ПОДОЖДИТЕ МИНУТКУ, ПОЖАЛУЙСТА',
    'connect': 'ПОДКЛЮЧИТЬСЯ',
    'nm.chng.cmnt': 'Вы пытаетесь сменить имя своего персонажа',
    'frc.nm.chng.cmnt': 'Вам придется сменить имя своего персонажа',
    'nm.chng.costs': 'Смена имени вам обойдется в',
    'ml.or.phn.chng.cmnt': 'Не задано',
    'ml.or.phn.chng.crrnt': 'Пригодится для восстановления пароля. Текущая почта или телефон:',
    'phn.format.desc': 'Телефон должен начинаться со знака +, а также содержать код страны и города',
    'set.new.name': 'Придумайте новый никнейм',
    'set.new.email.or.phn': 'Задайте новую почту или телефон',
    'banned': 'Вы не можете писать в чат, возможная причина: бан чата',
    'all.emotions': 'Все эмоции',
    'min.chat.lvl': 'Чтобы отправить сообщение нужен 10-й уровень!',
    'ent.text': 'Написать...',
    'pl.total': 'Всего игроков',
    'cl.total': 'Всего кланов',
    'online.total': 'Всего онлайн',
    'yan': 'ЯН',
    'gls': 'глс',
    'OK': 'ОК',
    'fm': 'ФМ',
    'kr': 'KR',
    'to.blck.list': 'В ЧЕРНЫЙ СПИСОК',
    'from.blck.list': 'ИЗ ЧЕРНОГО СПИСКА',
    'cust.slots': 'НАСТРОИТЬ СЛОТЫ',
    'backpack': 'РЮКЗАК',
    'poniatno': 'Понятно',
    'backpack.info': 'Предметы на бой подбираются случайным образом, если не задан алгоритм подбора предметов в слоты',
    'block.user': 'Добавить игрока в черный список? Это действие удалит всю переписку с данным игроком, и больше не позволит ему отправлять Вам сообщения.',
    'unblock.user': 'Убрать игрока из черного списка?',
    'blocked.feed': 'Игрок добавлен в черный список. Больше он не будет мешать Вам играть.',
    'unblocked.feed': 'Игрок убран из черного списка',
    'slots.set': 'Слоты настроены',
    'scroll': 'СВИТОК',
    'random': 'СЛУЧАЙНОЕ',
    'type': 'ТИП: ',
    'effect': 'ЭФФЕКТ: ',
    'power': 'СИЛА: ',
    'weakest': 'САМЫЙ СЛАБЫЙ',
    'strongest': 'САМЫЙ СИЛЬНЫЙ',
    'conf': 'Подтвердить',
    'cust': 'НАСТРОЙКА',
    'consls': 'ЗЕЛЬЯ И СВИТКИ',
    'cns.txt' : 'Настройте выбор зелий и свитков для боя. Задайте свои предпочтения для каждого слота. При наличии, в бой пойдут предметы согласно Вашим настройкам.',
    'continue' : 'Продолжить',
    'rej': 'ОТКАЗАТЬ',
    'invtd': 'Приглашен',
    'rjctd': 'Заявка отклонена',
    'need.accpt': 'Для регистрации необходимо принять политику конфиденциальности и дать разрешение на использование Cookie-файлов.',
    'priv.pol': 'политику конфиденциальности',
    'public.offer': 'публичную оферту',
    'user.agreement': 'пользовательское соглашение',
    'i.acc': 'Я принимаю',
    'acc.cook': 'Я даю согласие на использование Cookie.',
    'sublead': 'Временный лидер',
    'zamlead': 'Зам. лидера',
    '2.exch': 'К ОБМЕНУ',
    'sel.heroes': 'ОТМЕТЬТЕ ГЕРОЕВ, КОТОРЫХ ВЫ ОТДАДИТЕ В СЧЕТ ПОКУПКИ',
    'hero.dec.price': 'КАЖДЫЙ ОТДАННЫЙ ГЕРОЙ УМЕНЬШИТ СТОИМОСТЬ ПОКУПКИ',
    'her.sel': 'ВЫБРАНО ГЕРОЕВ:',
    'add.attack': 'атаки дополнительно',
    'h.shop': 'МАГАЗИН ГЕРОЕВ',
    'h.usual': 'ОБЫЧНЫЕ',
    'h.legend': 'ЛЕГЕНДАРНЫЕ',
    'h.myth': 'МИФИЧЕСКИЕ',
    'her.unavailable': 'Эти герои пока не доступны.',
    'min.lev.legend': 'Для доступа к Легендарным героям необходима победа над врагом мин. ',
    'min.lev.myth': 'Для доступа к мифическим героям необходима победа над врагом мин. ',
    'myth.her': 'МИФИЧЕСКИЕ ГЕРОИ',
    'myth.ava': 'Вам доступны мифические герои!',
    'too.many.her': 'Накопилось много неиспользуемых героев? Обменяйте их на мифического героя!',
    'myth.are.cool': 'Мифические герои обладают уникальными дарами, недоступными никакому другому. Они очень дорогие. Однако в счет покупки можно пустить старых ненужных героев.',
    'warlock': 'ЧЕРНОКНИЖНИК',
    'druid': 'ДРУИД',
    'witch': 'ВЕДЬМА',
    'shaman': 'ШАМАН',
    'dragonslayer': 'ДРАКОНОБОРЕЦ',
    'dragon_2': 'ДРАКОН РНГ.3',
    'warlock.gen': 'ЧЕРНОКНИЖНИКА',
    'druid.gen': 'ДРУИДА',
    'witch.gen': 'ВЕДЬМЫ',
    'shaman.gen': 'МОНАХА',
    'dragonslayer.gen': 'ДРАКОНОБОРЦА',
    'dragon_2.gen': 'ДРАКОНА РНГ.3',
    'can.not.improve.rank': 'ЭТО СУЩЕСТВО НЕ МОЖЕТ ПРОКАЧАТЬ РАНГ.',
    'success.purch': 'УСПЕШНАЯ ПОКУПКА!',
    'gems.arrived': 'ВАШИ ИЗУМРУДЫ ПРИБЫЛИ!',
    'h.pl': 'ЗДОРОВЬЕ ВСЕХ ГЕРОЕВ +',
    'disc.myth': 'СКИДКА НА МИФИЧЕСКИХ ГЕРОЕВ ',
    'get': 'ПОЛУЧИТЬ',
    'get.for.clan': 'ПОЛУЧИТЬ В КЛАН',
    'all.h': 'ВСЕМ ГЕРОЯМ',
    'disc.pl': '+СКИДКА ',
    'ev.myth': '% НА ЛЮБОГО МИФ./ВЕРХ. ГЕРОЯ',
    'us.inf.1': 'Здесь вы найдете недорогих, но неопытных героев.',
    'us.inf.2': 'Прокачав их, как следует, вы получите мощное подспорье своей команде.',
    'leg.inf.1': 'Легендарные - это опытные и сильные герои.',
    'myth.inf.1': 'Мифические герои обладают уникальными дарами, недоступными никакому другому.',
    'myth.inf.2': 'Они дорогие, однако в счет покупки можно пустить старых ненужных героев.',
    'myth.in.clan': '*Мифические герои доступны в магазине клана',
    'catto': 'Чешир',
    'joanne': 'Алиса',
    'johnny': 'Джонни',
    'news': 'НОВОСТИ',
    'manual': 'CПРАВКА',

    'c.chests': 'АКЦИЯ: СУНДУЧКИ!',
    'c.chests2': 'НОВЫЕ СУНДУЧКИ!',
    'c.wheel': 'КОЛЕСО УДАЧИ!',
    'c.hero': 'ГЕРОЙ В ПОДАРОК!',
    'c.snow_world': 'СНЕЖКА В ПОДАРОК',
    'c.boosters': 'БУСТЕР В ПОДАРОК!',
    'c.portal_discount': 'ПОРТАЛ В ПОДАРОК!',
    'c.gems_plus_50': 'ИЗЮМ В ПОДАРОК!',

    'c.chest': 'Акция: Получи сундук в подарок!',
    'c.wheel2': 'Акция: Крути колесо удачи!',
    'c.hero2': 'Акция: Получи героя в подарок!',
    'c.snow_world2': 'Акция: Получи пропуск в Снежку в подарок!',
    'c.boosters2': 'Акция: Получи бустеры в подарок!',
    'c.portal_discount2': 'Акция: Получи скидку до 100% на порталы!',
    'c.gems_plus_50_2': 'Акция: +50% к покупке изумрудов бесплатно!',

    'tickets.received': 'Билеты получены',
    'hero.received': 'Герой получен',
    'gems.received': 'Изумруды получены',
    'attack.increased': 'Атака увеличена',
    'defence.increased': 'Защита увеличена',
    'health.increased': 'Здоровье увеличено',
    'crystals.received': 'Кристаллы получены',
    'artifact.received': 'Артефакт получен',
    'force.received': 'Сила получена',

    'm.chest': 'МОЙ СУНДУК',
    'tickets.conds': 'Получите золотой билет за каждые {0} изумрудов, купленных в магазине!',
    'tickets.sources': 'Крутите колесо и выигрывайте ценные призы!',
    'wheel.det': 'Шансы выигрыша',
    'spin.for' : 'Крутить за ',
    'spin.free' : 'Крутить\nбесплатно',
    'take.award' : 'Получить\nнаграду',
    'you.have.free.spins' : 'Бесплатных кручений: ',
    'you.have' : 'У вас ',
    'silv.conds': 'Купи любое количество изумрудов и получи серебряный сундук!',
    'silv.det': 'Что может быть в серебряном сундуке?',
    'gold.conds': 'При покупке от 10 000 изумрудов получи золотой сундук.',
    'free.hero.conds': 'Купи от {0} изумрудов получи героя в подарок!',
    'free.snow.world.conds': 'Купи от {0} изумрудов получи бесплатный пропуск в Снежку на {1} часов.',
    'portal.discount.cond': 'Купи от {0} изумрудов получи скидку 50% на любой портал!',
    'portal.discount.cond2': 'Купи от {0} изумрудов получи скидку 100% на любой портал!',
    'booster.cond': 'Купи любое количество изумрудов и получи опыт +100% на 8 часов в подарок',
    'booster.cond2': 'Купи от {0} изумрудов, получи Платиновый набор на 72 часа в подарок (опыт за бой +400%, здоровье за уровень +400%, скорость регенерации x20).',
    'gold.det': 'Что может быть в золотом сундуке?',
    'camp.no.more': 'Акцией можно воспользоваться не более',
    'camp.wheel.limit': 'Число билетов в день ограничено (Получено билетов: {0}/{1})',
    'tms.used': '-х раз за день (использовано: ',
    'tm.used': '-го раза за день (использовано: ',
    'cmp.dates': 'Даты проведения акции: ',
    'slv.chst': 'СЕРЕБРЯНЫЙ СУНДУК',
    'gld.chst': 'ЗОЛОТОЙ СУНДУК',
    'az.chst': 'ЛАЗУРНЫЙ СУНДУК',
    'lv.chst': 'ЛАВОВЫЙ СУНДУК',
    'open': 'ОТКРЫТЬ',
    'all.her': 'всем героям',
    'one.her': 'одному герою',
    'chst': 'СУНДУК',
    'unique.hero.1': 'УНИКАЛЬНАЯ СОВА',
    'unique.hero.2': 'СОВА с 3-МЯ ДАРАМИ',
    'unique.hero.3': 'СОВА С 10-Ю ДАРАМИ',
    'hlth.plus': 'ЗДОРОВЬЕ +',
    'pick.who': 'Выбери, кто из героев получит ',
    'ft.news': 'Новости',
    'ft.sugg': 'Предложения',
    'ft.disc': 'Обсуждение игры',
    'ft.talk': 'Общение',
    'ft.play': 'Игры',
    'ft.help': 'Помощь по игре',
    'ft.letopis': 'Летопись',
    'ft.quest': 'Вопросы и ответы',
    'ft.meet': 'Знакомства',
    'ft.comp': 'Конкурсы',
    'ft.rules': 'Правила',
    'ft.cl.info': 'Информация',
    'ft.cl.stuff': 'Дела клана',
    'ft.cl.talk': 'Разговоры',
    'ft.cl.oth': 'Прочее',
    'frm': 'ФОРУМ',
    'adv': 'ОБЪЯВЛЕНИЕ',
    'clan.name': 'Название клана',
    'enemy.attack': 'Атака противника',
    'enemy.defence': 'Защита противника',
    'enemy.attack.defence': 'Атака и защита врага',
    'enemy.health': 'Здоровье противника',
    'player.attack': 'Атака героя',
    'player.defence': 'Защита героя',
    'player.attack.defence': 'Атака и защита героя',
    'player.health': 'Здоровье героя',
    'adv.desc': 'Напишите обьявление для своих соклановцев (максимум 180 символов)',
    'adv.placeholder': 'Текст обьявления',
    'publish': 'Опубликовать',
    'dlt': 'УДАЛЕНИЕ',
    'dlt.conf': 'Топик будет безвозвратно удален. Вы уверены?',
    'tpc.crt': 'СОЗДАТЬ ТОПИК',
    'tpc.crtn': 'СОЗДАНИЕ ТОПИКА',
    'tpc.edt': 'РЕДАКТИРОВАНИЕ ТОПИКА',
    'cmnts': 'КОММЕНТАРИИ',
    'com.edt': 'РЕДАКТИРОВАНИЕ КОММЕНТАРИЯ',
    'com.dlt.conf': 'Комментарий будет безвозвратно удален. Вы уверены?',
    'write': 'НАПИСАТЬ',
    'com.crtn': 'СОЗДАНИЕ КОММЕНТАРИЯ',
    'discn': 'ОБСУЖДЕНИЕ',
    'srch.sett': 'НАСТРОЙКИ ПОИСКА',
    'srch': 'ПОИСК',
    'arena.sett': 'НАСТРОЙКИ АРЕНЫ',
    'choose.arena.time': 'Выберите время для награды арены (по локальному времени)',
    'by.rate': 'ПО РЕЙТИНГУ',
    'by.new': 'ПО НОВИЗНЕ',
    'sort': 'СОРТИРОВКА',
    'tags': 'ТЕГИ',
    'filts': 'ФИЛЬТРЫ',
    'by.all.time': 'ЗА ВСЕ ВРЕМЯ',
    'by.week': 'ЗА НЕДЕЛЮ',
    'by.day': 'ЗА ДЕНЬ',
    'crtd': 'Создано: ',
    'edtd': ', отредактировано ',
    'delete': 'УДАЛИТЬ',
    'auth': 'Автор: ',
    'guest': 'Гостевой',
    'to.top': 'Перейти к топику >',
    'read.all': 'Читать полностью >',
    'cln.frm.pr': '*Для создания топика на форуме клана наобходимо иметь хотя-бы роль участника.',
    'comm.frm.pr': '*Для создания топика на общем форуме нужно иметь хотя-бы 10-й уровень и не иметь бана.',
    'private': 'Приватный',
    'top.name': 'Название топика',
    'top.cont': 'Содержание',
    'theme': 'Тематика топика',
    'gst.lbl': 'Приватный (для соклановцев) или гостевой (для всех)',
    'opr': 'ОПРОС',
    'dr.pl': 'Дорогой игрок!',
    'shr.opin': 'Поделитесь своим мнением:',
    'd.u.like': 'Вам нравится игра?',
    'rt.on.yan': 'Поставьте оценку нашей игре на Яндекс.',
    'rt.on.google': 'Поставьте оценку нашей игре в Google.',
    'hlp.evlv': 'Это поможет нам в развитии игры.',
    'rate': 'ОЦЕНИТЬ',
    'like': 'НРАВИТСЯ',
    'n.like': 'НЕ НРАВИТСЯ',
    'm.ty.op': 'Большое спасибо за Ваше мнение!',
    'ty.for.op': 'Благодарим за мнение',
    'we.do.best': 'Мы будем стараться быть лучше! :)',
    'boosters': 'БУСТЕРЫ',
    'now': 'СЕЙЧАС: ',
    'ttl': 'Название',
    'no.boo': 'БУСТЕР НЕ ДОСТУПЕН',
    'repl.boo': 'Заменить текущий бонус на данный бустер?',
    'boo.act': 'Активируем бустер ',
    'prol.boo': 'Продлить бустер еще на ',
    'act.till': 'ДЕЙСТВУЕТ ДО ',
    'repl': 'ЗАМЕНИТЬ',
    'append': 'ПРОДЛИТЬ',
    'ui.ch.ttl': 'Вид игры',
    'ui.nw': 'Переключить вид игры на новый (будет нужна перезагрузка игры)?',
    'ui.old': 'Переключить вид игры на оригинальный (будет нужна перезагрузка игры)?',
    'ui': 'ИНТЕРФЕЙС',
    'gfts': 'ПОДАРКИ',
    'gft': 'ПОДАРОК!',
    'gft.by.fotostrana': 'ПОДАРОК ОТ ФОТОСТРАНЫ!',
    'gft.by.vk': 'ПОДАРОК ОТ Vk Play!',
    'gft.by.text': 'Прими 1000 изумрудов в подарок. С любовью от VK Play.',
    'gft.fotostrana.text': 'Прими 50 изумрудов в подарок. С любовью от Фотостраны.',
    'with.us': 'Спасибо, что Вы с нами!',
    'gft.rcvd': 'Ура! Подарок получен!',
    'gft.all.rcvd': 'Ура! Все подарки приняты!',
    'gft.new': 'НОВЫЙ ПОДАРОК!',
    'u.have': 'У Вас',
    'n.g': 'новых подарка!',
    'ne.g': 'У Вас новый подарок!',
    'from2': 'от: ',
    'acpt.all': 'ПРИНЯТЬ ВСЕ',
    'nxt': 'СЛЕДУЮЩИЙ',
    'next.rang': 'следующий ранг',
    'force.has.max.rang': 'У этого дара \n максимальный ранг',
    'acpt': 'ПРИНЯТЬ',
    'gft.pck': 'ВЫБОР ПОДАРКА',
    'desire': 'ПОЖЕЛАНИЕ',
    'ent.desire': 'НАПИШИТЕ СОПРОВОДИТЕЛЬНЫЙ ТЕКСТ К ВАШЕМУ ПОДАРКУ',
    'ent.desire.2': 'Напишите пожелание игроку',
    'pres': 'ПОДАРИТЬ',
    'n.txt': '- без текста -',
    'g.d.conf': 'Вы действительно хотите удалить подарок?',
    'fr': 'ФРУКТЫ',
    'food': 'ЕДА',
    'ny': 'Н.Г.',
    'ans': 'ЗВЕРЬКИ',
    'mk.gft': 'ПОДАРИТЬ ПОДАРОК',
    'gft.snt': 'Вы отправили подарок игроку. Ему будет приятно!',
    'titan': 'ТИТАН',
    'titan.gen': 'ТИТАНА',
    'show.txt': 'Показать текст',
    'hide.txt': 'Скрыть текст',
    'txt.hid': 'Текст скрыт',
    't.h.all': 'Текст скрыт от всех.',
    't.s.all': 'Текст теперь виден всем.',
    'br.camp': 'Уникальное существо Брандашмыг со скидкой!',
    'v.o.ttl': 'ВЕРСИЯ',
    'v.o.int': 'ВЕРСИЯ УСТАРЕЛА',
    'v.o.body': 'Ваша версия устарела. Нажмите кнопку "Обновить". В некоторых случаях для подгрузки новой версии может также потребоваться перезагрузить браузер.',
    'cln.top': 'ТЕМА КЛАНА',
    'pick.thm': 'ВЫБЕРИТЕ ТЕМУ ДЛЯ СВОЕГО КЛАНА',
    'tpcs': 'ТЕМЫ',
    'thm.cmnt': 'ПЕРВЫЕ 3 РАЗА СМЕНА ТЕМЫ БЕСПЛАТНА. ДАЛЕЕ - 250 ИЗУМРУДОВ ИЗ БЮДЖЕТА КЛАНА.',
    'silv.gn': 'СЕРЕБРЯННОГО',
    'gld.gn': 'ЗОЛОТОГО',
    'brl.gn': 'БРИЛЛИАНТОВОГО',
    'silv.nm': 'СЕРЕБРЯННЫЙ',
    'gld.nm': 'ЗОЛОТОЙ',
    'brl.nm': 'БРИЛЛИАНТОВЫЙ',
    'bon.gms': ' БОНУСНЫХ ИЗУМРУДОВ!',
    'by.mr': 'Купите еще',
    'gms.silv': 'изумрудов и получите серебряный статус.',
    'det': 'ПОДРОБНЕЕ',
    'auto.rotation': 'авто-вращение',
    'cngrts.stat': 'ПОЗДРАВЛЯЕМ С ПРИСВОЕНИЕМ',
    'b.sts': 'СТАТУСА!',
    'st.desc.1': 'Теперь за каждую покупку Вам будут начисляться бонусные баллы в размере ',
    'st.desc.2': '(вы получаете бонусные баллы в размере ',
    'pct.gms': '% от купленных изумрудов.',
    'pnts.got': 'ВАМ НАЧИСЛЕНО ',
    'pnts': 'БОНУСНЫХ БАЛЛОВ',
    'pnts.nom': 'БОНУСНЫЕ БАЛЛЫ',
    'mk.purch.dr.week': 'Совершите покупку в течение недели и получите дополнительно по одному изумруду за каждый бонусный балл.',
    'bn.lim': '* Но не более размера покупки',
    'u.hv': 'У ВАС ',
    'stts': ' СТАТУС',
    'mx.st': 'У Вас наивысший статус.',
    'buy.get': ' изумрудов в совокупности (без учета подарочных) и получите ',
    'before.obs': 'до сгорания:',
    'ag.buy': 'Купите ',
    'ag.buy.mr': 'Купите еще ',
    'rem': 'ОСТАЛОСЬ',
    'bf.obs': ' ДО СГОРАНИЯ БОНУСНЫХ БАЛЛОВ!',
    'u.hv.bns': ' У вас бонусных баллов:',
    'hv.bns': '(бонусных баллов:',
    'bt.agit': 'Совершите покупку, чтобы получить за них изумруды.',
    'pct.fr': '% от суммы покупки)',
    'or.get': 'ИЛИ ПОЛУЧИТЕ',
    'pl.btl': '+1 БОЙ ЗА',
    'do': 'ДО',
    'to.the': 'ДО',
    'w.adv': 'ПРОСМОТР РЕКЛАМЫ',
    'w.rol': 'ЗА ПРОСМОТР РОЛИКА',
    '4gems': 'ЗА ИЗУМРУДЫ',
    't.p.d': '-Х РАЗ В ДЕНЬ',
    'w.a.g': 'ПОСМОТРИТЕ РЕКЛАМУ И ПОЛУЧИТЕ ',
    'ws.tdy': 'ПРОСМОТРОВ ЗА СЕГОДНЯ',
    'wtch': 'СМОТРЕТЬ',
    'gn.gems': 'ЗАРАБОТАТЬ ИЗУМРУДЫ',
    '2.mr': 'НЕ БОЛЕЕ',
    'rew.lim': 'ЗА ДЕНЬ МОЖНО ПРОСМОТРЕТЬ НЕ БОЛЕЕ 3-Х РАЗ.',
    'aw': 'НАГРАДА',
    'gt': 'ПОЛУЧИТЕ',
    'rew.res': 'Благодарим за просмотр рекламы! Просмотров за сегодня: ',
    'val': 'ЛЮБОВЬ',
    'ifrit': 'ИФРИТ',
    'ifrit.gen': 'ИФРИТА',
    'life': 'ЖИЗНЬ',
    'life.gen': 'ЖИЗНИ',
    'death': 'СМЕРТЬ',
    'death.gen': 'СМЕРТИ',
    'chaos': 'ХАОС',
    'chaos.gen': 'ХАОСА',
    'wall': 'НЕПРИСТУПНОСТЬ',
    'wall.gen': 'НЕПРИСТУПНОСТИ',
    'lord': 'ПОВЕЛИТЕЛЬ',
    'lord.gen': 'ПОВЕЛИТЕЛЯ',
    'vamp': 'ВАМПИР',
    'vamp.gen': 'ВАМПИРА',
    'luck': 'ФОРТУНА',
    'luck.gen': 'ФОРТУНЫ',
    'bers': 'БЕРСЕРК',
    'bers.gen': 'БЕРСЕРКА',
    'wind': 'ВЕТЕР',
    'wind.gen': 'ВЕТРА',
    'sorc': 'ЧАРОДЕЙ',
    'sorc.gen': 'ЧАРОДЕЯ',
    'east': 'ПАСХА',
    'sst': 'ШТ',
    'plc': 'МЕСТО',
    'km.snow': 'ДИСТАНЦИЯ В СНЕЖ. МИРЕ',
    'met': 'метров',
    'b.snow': 'Увеличивает максимальную дистанцию в снежном мире',
    'm.p.lev': 'метров за уровень',
    'p.lev': 'за уровень',
    'copy': 'Копировать ссылку',
    'pin.topic': 'Закрепить топик',
    'unpin.topic': 'Открепить топик',
    'copied': 'Ссылка скопирована',
    'card.used': 'Карта использована!',
    'spr': 'СПРАВКА',
    'spr.forces': 'СПРАВКА ПО ДАРАМ',
    'sprng': 'МАЙСКИЕ',

    'june-3': 'Объявление! С 3-го июня начинают действовать новые правила обмена героев.',

    'pck.ava': 'Выберите себе новый аватар',
    'lvlng': 'ПРОКАЧКА',

    'lvlg.comb': 'Групповая прокачка, действующая "на всех героев" действует максимально на 6 героев. Сейчас предпочтение отдается боевым героям. Однако, Вы можете переключить настройку, чтобы предпочтение отдавалось самым слабым героям. Переключить?',
    'lvlg.weak': 'Групповая прокачка, действующая "на всех героев" действует максимально на 6 героев. Сейчас предпочтение отдается слабейшим героям. Однако, Вы можете переключить настройку, чтобы предпочтение отдавалось боевым героям. Переключить?',
    'lvlg.comb.st': 'Настройка изменена. Теперь качаются самые слабые герои',
    'lvlg.weak.st': 'Настройка изменена. Теперь качаются боевые/самые сильные герои',

    'angel': 'АНГЕЛ',
    'archangel': 'АРХАНГЕЛ',
    'archititan': 'АРХИТИТАН',
    'yotun': 'ЙОТУН',
    'asg.phonix': 'АСГ. ФЕНИКС',
    'asg.phonix.full': 'АСГАРДИАНСКИЙ ФЕНИКС',
    'angel.gn': 'АНГЕЛА',
    'archangel.gn': 'АРХАНГЕЛА',
    'archititan.gn': 'АРХИТИТАНА',
    'yotun.gn': 'ЙОТУНА',
    'asg.phonix.gn': 'АСГ. ФЕНИКСА',
    'min.lev.top': 'Для доступа к верховным героям необходима победа нам врагом мин. ',
    'top.her.t': 'ВЕРХОВНЫЕ ГЕРОИ',
    'top.her.n': 'Вам доступны верховные герои!',
    'top.her.1': 'Верховные герои - это сильнейшие герои в игре.',
    'top.her.2': 'Как правило, верховные герои обладают дарами вплоть до 5-го уровня',
    'h.top': 'ВЕРХОВНЫЕ',

    'top.inf.1': 'Для верховных героев недоступен обмен. Однако на них действуют все те же скидки, что на мифических героев.',

    'quality': 'КАЧЕСТВО',
    'qi': 'Качество изображения',
    'qi.set': 'Установите качество изображений. На мощных устройствах рекомендуется высокое качество. Но на устаревших устройствах лучше использовать низкое качество для лучшей производительности.',
    'nd.re': 'Для вступления изменения в силу понадобится перезагрузка игры',
    'q.lst': 'Оч. низкое',
    'q.lw': 'Низкое',
    'q.st': 'Стандартное',
    'q.hg': 'Высокое',
    'knl.d': 'Д. знаний!',
    'it.levs': 'ЛИНЕЙКА УРОВНЕЙ ПРЕДМЕТА:',
    'impr': 'Улучшение: ',
    'spdp': 'Ускорение: ',
    'art.d': 'СКИДКИ НА ВЕЩИ!',
    'art.d.d': 'СКИДКИ ОТ 50% АБСОЛЮТНО НА ВСЕ ПРЕДМЕТЫ В МАГАЗИНЕ.',
    'watch': 'ПОСМОТРЕТЬ',
    't.c.e': 'ДАТЫ АКЦИИ: ',

    'clan.invite': 'пригласил в клан',
    'clan.joined': 'присоединился к клану',
    'clan.reject': 'отклонил приглашение в клан',
    'clan.kick': 'выгнал из клана',
    'clan.left': 'покинул клан',
    'clan.raise': 'повысил до новой должности',
    'clan.subsidence': 'понизил',
    'clan.goldDonated': 'пожертвовал золото в клан',
    'clan.redCrystalDonated': 'пожертвовал красные кристаллы в клан',
    'clan.blueCrystalDonated': 'пожертвовал синие кристаллы в клан',
    'clan.gemsDonated': 'пожертвовал изумруды в клан',
    'clan.gearsDonated': 'собрал шестеренки для клана',
    'clan.leaderReplace': 'передал лидерские полномочия на',
    'clan.created': 'запустил улучшение постройки',
    'clan.subLeader': 'назначил заместителя в клане',
    'clan.upgradeWallOfFame': 'улучшил Стену Славы клана',
    'clan.changeDescription': 'изменил описание клана',
    'clan.changeTheme': 'изменил тему клана',
    'clan.giveAdvertisement': 'разместил обьявление в клане',
    'clan.changeDifficulty': 'изменил сложность задания клана',
    'one.free': 'Раз в день бесплатно!',
    'hlw': 'Хэллоуин',
    'wall.r.7': 'НЕПРИСТУПНОСТЬ РНГ. 7',
    'wall.r.7.gn': 'НЕПРИСТУПНОСТИ РНГ. 7',
    'death.r.7': 'СМЕРТЬ РНГ. 7',
    'death.r.7.gn': 'СМЕРТИ РНГ. 7',
    'witch.5.1': '- X6 ПРОТИВ ГОЛЕМОВ',
    'witch.5.2': '- ИГНОРИРУЕТ ЩИТ ГОЛЕМА',
    'witch.5.3': '- ЗАСТАВЛЯЕТ ПРОТИВНИКА АТАКОВАТЬ СОЮЗНОГО ГЕРОЯ ДВАЖДЫ',
    'witch.7.1': '- X6 ПРОТИВ ГОЛЕМОВ, ИГНОРИРУЕТ ЩИТ ГОЛЕМА',
    'witch.7.2': '- ЗАСТАВЛЯЕТ ПРОТИВНИКА АТАКОВАТЬ СОЮЗНОГО ГЕРОЯ ДВАЖДЫ',
    'witch.7.3': '- ПРИНУЖДАЕТ ПРОТИВНИКОВ К ГРУППОВОЙ АТАКЕ ВМЕСТЕ С ВАМИ',
    'skip.mv': 'ПРОПУСТИТЬ ХОД',
    'sk.mv': 'ПРОПУСК ХОДА',
    'mag.r.2': 'МАГ РНГ.2',
    'mag.r.2.gn': 'МАГА РНГ.2',
    'chaos.r.7': 'ХАОС РНГ. 7',
    'chaos.r.7.gn': 'ХАОСА РНГ. 7',
    'chaos.7.1': 'ИГНОРИРУЕТ 99.9999% ЗАЩИТЫ ПРОТИВНИКА',
    'pl.on': 'Игроку на',
    'th.pl': '-м месте',
    'rl': 'Длина дороги: ',
    'km': 'км.',
    'h.d': ' - большая скидка!',
    't.p': 'В ПОРТАЛ',
    'pt': 'ПОРТАЛ',
    'discount': 'СКИДКА',
    '8mr': '8 Марта',
    'brth': 'ДР Игры',
    'cat.rain': 'Это еще что!?',
    'hum.rain': 'Это просто дождь, друзья. Такое бывает. Скоро закончится.',
    'ntr': 'Новое задание: За белым кроликом!',
    'help': 'НА ПОМОЩЬ',
    'h.h': 'Я слышу зов о помощи. Кто-то в беде! Вперед!',
    'thirty.emeralds': '30-ю изумрудами',
    'hundred.emeralds': '100 изумрудами',
    'five.hundred.emeralds': '500 изумрудами',
    'thousand.emeralds': '1000 изумрудов',
    'gft.by.code': 'ПОДАРОК ПО КОДУ!',
    'gft.by.code.text': 'По промокоду VIP Вы получаете подарочный сундук с ',
    'gft.by.code.text2': ' изумрудов!',
    'with.love': 'С любовью, Ваше Зазеркалье!!!',
    'm': 'м.',
    'pc.': 'шт.',
    'card.elephant': 'Карта Боевой слон',
    'card.elephant.tutorial': 'Теперь просто выберите героя и дар так, как обычно. И наслаждайтесь нулевой ценой на мгновенное улучшение.',
    'you.activated.card':'Вы активировали карту!',
    'available': 'В наличии',
    'card.is.active': 'Карта активна',
    'choose.hero.and.improve.force': 'Выберите героя и улучшите дар',
    'ban.reason': 'Выберите причину бана',
    'ban.reason.flood': 'Флуд',
    'ban.reason.profanity': 'Мат',
    'ban.reason.insults': 'Оскорбления',
    'ban.reason.provocative.action': 'Провокация',
    'ban.reason.trade': 'Торговля',
    'ban.reason.advertisement': 'Торговля',
    'ban.reason.fraud': 'Мошенн-во',
    'ban.reason.nickname': 'Запр. ник',
    'ban.reason.banned.topics': 'Запр. темы',
    'ban.reason.laws': 'Законы РФ',
    'hero.for.the.quest': 'ВЫПОЛНИТЕ ЗАДАНИЕ И ПОЛУЧИТЕ МИФИЧЕСКОГО ГЕРОЯ ',
    'cost': ' СТОИМОСТЬЮ ',
    'emeralds': ' ИЗУМРУДОВ.',
    'emeralds.small': 'изумрудов ',
    'sulphur': ' серы.',
    'share': 'ПОДЕЛИТЬСЯ (+15 изумрудов)',
    'share2': 'ПОДЕЛИТЬСЯ',
    'text.is.too.long': 'Слишком длинный текст',
    'level': ' (ур. ',
    'purchase': 'Покупка ',
    'emeralds.for': ' изумрудов за ',
    'payment.id': 'Id платежа: ',
    'payment.srated': 'ПЛАТЕЖ НАЧАТ',
    'emeralds.accrued.but.not.received': 'ИЗУМРУДЫ НАЧИСЛЕНЫ, НО НЕ ПОЛУЧЕНЫ',
    'return': 'ВОЗВРАТ',
    'gain.part.of.friend.emeralds': 'Приглашайте друзей и получайте до 45% изумрудов с каждой их покупки',
    'send.ref.link.to.friend': 'ОТПРАВЬТЕ УНИКАЛЬНУЮ ССЫЛКУ ДРУГУ',
    'filter.by.category': 'Фильтровать по категории:',
    'filter.by.subcategory': 'Фильтровать по подкатегории:',
    'filter': 'Фильтровать',
    'social.invite.text': 'Играй в Зазеркалье вместе со мной!',
    'social.invite.bonus': 'Получи бонус 99 изумрудов на старте игры!',
    'again.in': 'ЕЩЕ РАЗ В ',
    '1h': '1 чаc',
    '3h': '3 часа',
    '6h': '6 часов',
    '12h': '12 часов',
    '1d': '1 день',
    '3d': '3 дня',
    '1w': 'одна неделя',
    '2w': 'две недели',
    'copied.large': 'СКОПИРОВАН',
    'copied.id': 'ID ДЛЯ ВСТАВКИ СКОПИРОВАН',
    'source': 'ИСТОЧНИК:',
    'unban': 'РАЗБАНИТЬ',
    'unban.question': 'Разбанить игрока?',
    'log.in.as.a.player': 'ВОЙТИ ИГРОКОМ',
    'history': 'ИСТОРИЯ',
    'payments': 'ПЛАТЕЖИ',
    'moderator.upgrade': 'ПОВЫСИТЬ ДО МОДЕРА',
    'moderator.upgrade.success': 'Игрок поднят до модера',
    'moderator.downgrade': 'ПОНИЗИТЬ ДО ИГРОКА',
    'moderator.downgrade.success': 'Модер понижен до простого смертного',
    'more': 'ДАЛЕЕ',
    'openly.improve': 'ОТКРЫТО УЛУЧШЕНИЕ ДО ',
    'level.up': ' УРОВНЯ!',
    'mythical.hero': 'МИФ. ГЕРОЙ',
    'heroes.island': 'ОСТРОВ ГЕРОЕВ',
    'not.enough.amethysts': 'Не хватает аметистов',
    'amethysts.you.have': 'У Вас аметистов: ',
    'angels.city': 'ГОРОД АНГЕЛОВ',
    'accept.angel': 'Примите ангела в свои ряды.',
    'appmetrica.active': 'AppMetrica активируется',
    'appmetrica.success': 'AppMetrica успешно активировалась',
    'appmetrica.error': 'Произошла ошибка при активации AppMetrica',
    'get.gift': 'ПОЛУЧИ ПОДАРОК!',
    'join.group': 'ВСТУПИ В ГРУППУ',
    'join.group.official': 'ВСТУПИ В ОФИЦИАЛЬНУЮ ГРУППУ В ОДНОКЛАССНИКАХ',
    'donate': 'ДАРИМ ',
    'donate.all': ' ИЗУМРУДОВ ВСЕМ УЧАСТНИКАМ ГРУППЫ!',
    'you.are.in.group': 'Вы состоите в группе. Примите изумруды!',
    'join.group.donate': 'Вступите в группу, чтобы получить изумруды.',
    'you.got.amethysts': 'Изумруды получены!',
    'accept.donate': 'Accept the emeralds',
    'in.group': 'В группу',
    'oops': 'Упс',
    'you.used.action': 'Вы уже воспользовались акцией.',
    'heroes.gate': 'ЗАМОК ГЕРОЕВ',
    'buy.unique.hero.from.castle': 'Купите уникального героя из замка героев.',
    'bath.broom': 'Банный веник, свежее полотенце и тапочки выданы',
    'player.unbanned': 'Игрок разбанен',
    'accept.15.donate': 'Примите 15 изумрудов!',
    'game.credits': 'Игровая валюта Зазеркалье',
    'market.s': 'РЫНОК СЮРА',
    'accept.hero.from.market.s': 'Примите героя из Рынка сюра в свои ряды.',
    'titan.gate': 'ЗАМОК ТИТАНОВ',
    'buy.unique.hero.from.castle.titan': 'Купите уникального героя из замка титанов.',
    'city.yotunov': 'ГОРОД ЙОТУНОВ',
    'accept.hero.from.city.yotunov': 'Примите йотуна в свои ряды.',
    'intro': 'Зазеркалье: приключения, сражения, котики. Присоединяйтесь к игре!',
    'game.name': 'Игра Зазеркалье',
    'htr': 'Отлично',
    'mults': 'МУЛЬТЫ',

    'koschey': 'КОЩЕЙ',
    'koschey.gn': 'КОЩЕЯ',
    'cyclop': 'ЦИКЛОП',
    'cyclop.gn': 'ЦИКЛОПА',
    'poison': 'НАГА',
    'poison.gn': 'НАГИ',
    'keeper': 'ХРАНИТЕЛЬ',
    'keeper.gn': 'ХРАНИТЕЛЯ',
    'sphinx': 'СФИНКС',
    'sphinx.gn': 'СФИНКСА',
    'norn': 'НОРН',
    'norn.gn': 'НОРНЫ',
    'provocator': 'ПРОВОКАТОР',
    'provocator.gn': 'ПРОВОКАТОРА',
    'shadow': 'ТЕНЬ',
    'shadow.gn': 'ТЕНИ',
    'priestess': 'ЖРИЦА',
    'priestess.gn': 'ЖРИЦЫ',
    'as': 'АС',
    'as.gn': 'АСА',
    'young': 'МОЛОДЫЕ',
    'epic': 'ЭПИЧЕСКИЕ',
    'shad.tn': 'ПРИЗРАЧНЫЙ ГОРОД',
    'get.p': 'РАСПАКОВКА ПОКУПОК',
    'h.w.u': 'ГЕРОЙ ТЕПЕРЬ С НАМИ!',
    'also.bon': 'А ТАКЖЕ БОНУСНЫЕ ИЗУМРУДЫ!',
    'also.bon.2': 'А ТАКЖЕ ПО БОНУСНОЙ ПРОГРАМЕ!',
    's.h': 'МЕШОК ЗДОРОВЬЯ',
    'p.all': '% КАЖДОМУ ГЕРОЮ',
    'tick': 'БИЛЕТИКИ ДЛЯ КОЛЕСА!',
    'all.hero.7': 'Дары всех героев можно улучшить до 7 ранга',
    'ns.f': 'Для призыва героев нужна мощь Нук-Серры',
    'hv': 'ИМЕЕТСЯ: ',
    'disc.mth': ' НА МИФ. ГЕРОЕВ ',
    'free.gms': 'БЕСПЛАТНЫЕ ИЗУМРУДЫ, ПРОСТО ЗАБЕРИТЕ!',
    'an.y.m': 'Другой.. Вы',
    'an.y.w': 'Другая.. Вы',
    'cont': 'ПРОДОЛЖЕНИЕ!',
    'and.nw': 'А ТЕПЕРЬ...',
    'wlcm': 'ДОБРО ПОЖАЛОВАТЬ!',
    'wn': 'ЧТО ЖЕ БУДЕТ ДАЛЬШЕ?',
    't.a': 'ВПЕРЕД, К ПРИКЛЮЧЕНИЯМ!',
    'port.shp': 'Портал призыва',
    'sht.sur': 'Сюр',
    'sht.port': 'Портал',
    'sm.cln': 'Соклановец',
    'kln': 'Клан: ',
    'by.ap': 'По яблокам',
    'pr.free': 'Бесплатный вход в любой портал',
    'az.conds': 'Купи от 3000 изумрудов и получи Лазурный сундук!',
    'az.det': 'Что может быть в Лазурном сундуке?',
    'lv.conds': 'Купи от 50 000 изумрудов и получи Лавовый сундук!',
    'lv.det': 'Что может быть в лавовом сундуке?',
    'by.bk': 'По книгам',

    'p1.1': '*** Вы прошли 1-ю часть игры! ***',
    'p1.2': 'Это было удивительное, сказочное приключение.',
    'p1.3': 'И оно продолжается!',
    'p1.4': 'Вы уже летите через портал в Легендарный Асгард.',
    'p1.5': 'А пока примите пламенные поздравления!',
    'p1.6': 'Оставайтесь с нами, в самой эпической игре - в Зазеркалье!',
    'p1.7': 'Примите самые теплые пожелания, и успехов на Вашем пути.',
    'p1.8': 'Тем временем Вы уже видите свет...',
    'p1.9': 'Асгард ждет Вас.',
    'p1.10': 'ЧАСТЬ II.',
    'lgo': 'В ПУТЬ!'
}
